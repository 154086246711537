import { Collapse } from 'bootstrap';
import globalMessages from '../globalMessages';
import {nytNotification, checkForm, getCookie, setCookie} from '../globalFunctions';

const SlideInCta = {
    init: () => {
        document.getElementById('js-slide-cta-btn')?.addEventListener('click', SlideInCta.submitCollapse);
        document.querySelector('.sec-main--slide-cta-close')?.addEventListener('click', SlideInCta.closeCollapse);
    },

    submitCollapse: (e) => {
        e.preventDefault();
        const collapseEl = document.getElementById('collapseSlideForm') as HTMLFormElement;
        
        const bsCollapse = new Collapse(collapseEl, {toggle: false});
        bsCollapse.show();

        if(collapseEl.classList.contains('show') && checkForm(collapseEl)) {
            const submitBtn = e.currentTarget,
            formData = $('.sec-main--slide-cta').serializeForm();
            submitBtn.classList.add('loading');
            submitBtn.setAttribute('disabled', '');

            $.ajax({
                url: '/pardot/slide-cta/',
                type: 'POST',
                data: formData,
                success: function (response) {
                    submitBtn.classList.remove('loading');
                    submitBtn.removeAttribute('disabled');
                    if(response.success) {
                        // Pardot Form Submission event tracking
                        syn4('form_submit', {'conversion': 'Yes', 'page_url': 'Home Page', 'name': 'Slide Form'});
                        // hide unnecesary elements and display only success message
                        document.querySelectorAll('#collapseSlideForm, #js-slide-cta-btn, .sec-main--slide-cta-subheadline')?.forEach(el => el.classList.add('d-none'));
                        document.querySelector('.sec-main--slide-cta-headline')!.innerHTML = `Thank you! <br /> ${response.confirmation_message}`;
                        setCookie(`scta-${formData.id}`, 'hide', 30);
                    } else {
                        nytNotification('error', globalMessages().genericRequestError);
                        // display corresponding errors for fields
                        Object.keys(response.errors).forEach(error => {
                            const errorSpan = document.querySelector(`.sec-main--slide-cta .error.${error}`) as HTMLSpanElement;
                            errorSpan.innerText = response.errors[error];
                            errorSpan.closest('.form-group')?.classList.add('has-error');
                        });
                    }
                },
                error: function () {
                    submitBtn.classList.remove('loading');
                    submitBtn.removeAttribute('disabled');
                    nytNotification('error', globalMessages().genericRequestError);
                }
            });
        } else {
            // Banner Button event tracking
            syn4('cta_click', {'page_url': window.location.href, 'name': 'Service CTA', 'click_text': (document.querySelector('.sec-main--slide-cta-headline') as HTMLElement).innerText, 'destination': ''});
        }
    },

    closeCollapse: () => {
        const slideId = (document.getElementById('js-slide-cta-id') as HTMLInputElement).getAttribute('value');
        setCookie(`scta-${slideId}`, 'hide', 30);
        SlideInCta.slideToggle('slide-out');
    },

    slideToggle: (param: string) => {
        const slideForm = document.querySelector('.sec-main--slide-cta');
        slideForm?.classList.add(param);
    }
};

export default SlideInCta;
