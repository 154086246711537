import $ from 'jquery';
import { Modal } from 'bootstrap';
import globalMessages from '../globalMessages';
import {nytConfirm, nytNotification, errorClean} from '../globalFunctions';

// $ is the export of the jquery module.
const SavedSearch = {
    init: () => {
        $('.js-save-search-btn').on('click', SavedSearch.initSearchSaveModal);
        $('[id^="js-modal-rename-"]').on('click', SavedSearch.initSearchRenameModal);
        $('.js-delete-btn').on('click', SavedSearch.initSearchDeleteModal);
        $('#js-saved-search').on('click', SavedSearch.saveSearch);
        $('#js-confirm-search-rename').on('click', SavedSearch.renameSearch);
        $('#js-saved-search-copy').on('click', SavedSearch.copySearchURL);
        $(".sec__main--saved-search tbody :checkbox").on("change", SavedSearch.checkItem);
        $('.select-all').on('click', SavedSearch.selectAll);
        SavedSearch.getSavedSearchesLength();
    },

    initSearchSaveModal: () => {
        $('.sec__main--saved-search .modal-title').html("Save Search Criteria");
        $('.sec__main--saved-search #js-saved-search-copy').hide();
        $('.sec__main--saved-search #js-link-saved-search').html('');
        $('.sec__main--saved-search #js-saved-search').removeAttr('disabled');
        $('.sec__main--saved-search #js-saved-search-name').show();
        $('.sec__main--saved-search #js-saved-search-name').val('');
        $('.sec__main--saved-search .js-saved-search-name-label').show();
        $('.sec__main--saved-search #js-saved-search').show();
    },

    initSearchRenameModal: (event) => {
        $('#js-update-search-name-error').empty().closest('.form-group').removeClass('has-error');

        const element = $(event.target);
        if (!element) {
            return;
        }
        const searchTitle = element.attr('data-title');
        const searchId = element.attr('data-id');
        $('#js-update-search-name')
            .val(`${searchTitle}`)
            .attr('data-id', `${searchId}`);
    },

    initSearchDeleteModal: (event) => {
        const element = $(event.target);
        const title = element.attr('data-title');
        const searchTitle = title!.replace(/<\/?[^>]+(>|$)/g, "");
        const searchId = element.attr('data-id');
        let message;
        if (searchTitle === 'multiple-delete-btn') {
            message = `<span id="js-delete-search-name" data-id="${searchId}"><strong>selected</strong> saved searches</span>`;
        } else {
            message = `the saved search "<span id="js-delete-search-name" data-id="${searchId}"><strong>${searchTitle}</strong></span>"`;
        }

        nytConfirm("You're about to remove " + message + " from your profile. Please confirm.", {
            yesText: 'Delete',
            noText: 'Cancel',
            onConfirm: function () {
                SavedSearch.deleteSearch(searchId);
            }
        });
    },

    saveSearch: (event) => {
        event.preventDefault();
        $('#js-saved-search-name-error').empty().closest('.form-group').removeClass('has-error');
        $('#js-saved-search').attr('disabled', 'disabled');

        const formData = $('#searchForm').serialize();
        const title = ($('#js-saved-search-name').val() as string).trim();
        const data = {form_data: formData, name: title, action: 'save_search'};
        if (title.length) {
            $.ajax({
                url: '/search/',
                type: 'POST',
                data: JSON.stringify(data),
                success: function (response) {
                    SavedSearch.saveSearchSuccess(response);
                },
                error: function () {}
            });
        } else {
            $('#js-saved-search-name-error')
                .html(globalMessages().savedSearchFieldError)
                .closest('.form-group').addClass('has-error');
            $('#js-saved-search').removeAttr('disabled');
            $('#js-saved-search-name').off().on('input', errorClean);
        }
    },

    saveSearchSuccess: (response) => {
        if (response.success) {
            $('.sec__main--saved-search .modal-title').html("'" + response.saved_search[0].title + "' saved!");
            $('.sec__main--saved-search #js-saved-search-name').hide();
            $('.sec__main--saved-search .js-saved-search-name-label').hide();
            $('.sec__main--saved-search #js-saved-search').hide();
            $('.sec__main--saved-search #js-saved-search-copy').show();
            $('.sec__main--saved-search #js-link-saved-search').html(response.saved_search[0].url);

            // Update header navbar saved searches
            const savedSearchElement = $('.js-count-saved-search'),
                count = parseInt(savedSearchElement.html(), 10),
                savedSearchContainer = $('#js-saved-searches-container');

                savedSearchElement.html(`${count + 1}`);

            savedSearchContainer.empty();
            $.each(response.saved_search, (i, item) => {
                savedSearchContainer.append (
                    `<a class="dropdown-item" target="_self" href="${item.url}">${item.title}</a>`
                );
            });
            savedSearchContainer.append (
                '<a href="/saved-search/" class="dropdown-item manage" target="_self">Manage Saved Searches</a>'
            );

            $('.sec__main--saved-search #js-saved-search').removeAttr('disabled');
        } else {
            $('#js-saved-search-name-error')
                .html(response.errors.name)
                .closest('.form-group').addClass('has-error');
            $('.sec__main--saved-search #js-saved-search').removeAttr('disabled');
        }
    },

    renameSearch: (event) => {
        event.preventDefault();
        $('#js-update-search-name-error').empty().closest('.form-group').removeClass('has-error');
        const searchNameField = document.getElementById('js-update-search-name') as HTMLInputElement,
        data_id = searchNameField.getAttribute('data-id'),
        newName = (searchNameField.value + '').trim();

        const data = {
            action: 'rename',
            field: 'title',
            value: newName,
            search_ids: [data_id]
        };
        if (newName.length) {
            $.ajax({
                url: '/saved-search/',
                type: 'POST',
                data: JSON.stringify(data),
                success: function (response) {
                    SavedSearch.renameSearchSuccess(response);
                },
                error: function () {
                    nytNotification('error', globalMessages().savedSearchRenamedError);
                    $('#js-update-search-name').off().on('input', errorClean);
                }
            });
        } else {
            $('#js-update-search-name').off().on('input', errorClean);
            $('#js-update-search-name-error')
                .html('Please enter a name before saving.')
                .closest('.form-group').addClass('has-error');
        }

    },

    renameSearchSuccess: (response) => {
        if (response.success) {
            const modalInit = Modal.getInstance(document.getElementById('id_modal_confirmation_rename') as HTMLElement);
            // Update modal
            modalInit && modalInit.hide();
            const searchID = response.data.search_ids[0],
            searchTitle = response.data.value;
            $(`#js-modal-rename-${searchID}`).attr('data-title', `${searchTitle}`);
            $(`#js-modal-delete-${searchID}`).attr('data-title', `${searchTitle}`);

            // Update Saved Search entry
            (document.querySelector(`#js-search-name-${searchID} a`) as HTMLElement).innerText = searchTitle;

            // Update header navbar saved searches
            (document.querySelector(`#js-saved-search-navlink-${searchID} a`) as HTMLElement).innerText = searchTitle;
            nytNotification('success', globalMessages().savedSearchRenamed);

        } else {
            $('#js-update-search-name').off().on('input', errorClean);
            $('#js-update-search-name-error')
                .html(response.error)
                .closest('.form-group').addClass('has-error');
        }
    },

    deleteSearch: (searchId) => {
        const MULTIPLE_DELETE = 'multiple-delete-btn';
        const data_id = searchId;

        if (data_id === MULTIPLE_DELETE) {
            SavedSearch.multipleDeleteSearch();
        } else {
            const data = {
                action: 'delete',
                search_ids: [data_id]
            };
            $.ajax({
                url: '/saved-search/',
                type: 'POST',
                data: JSON.stringify(data),
                success: function (response) {
                    SavedSearch.deleteSearchSuccess(response, data_id)
                },
                error: function () {
                    nytNotification('error', globalMessages().savedSearchDeletedError);
                }
            });
        }

    },

    deleteSearchSuccess: (response, data_id)=> {
        if (response.success) {
            $('#js-saved-search-' + data_id).remove();
            // Update header navbar saved searches
            const savedSearchElement = $('.js-count-saved-search');
            const count = parseInt(savedSearchElement.html(), 10);
            savedSearchElement.html((count - 1) + '');
            $('#js-saved-search-navlink-' + data_id).remove();
            nytNotification('success', globalMessages().savedSearchDeleted);
            SavedSearch.getSavedSearchesLength();
        }
    },

    copySearchURL: (event) => {
        event.preventDefault();
        let range;
        const saved_search_link_elem = document.getElementById('js-link-saved-search');

        if (window.getSelection) {
            range = document.createRange();
            range.selectNode(saved_search_link_elem);
            window.getSelection()!.addRange(range);
        }
        document.execCommand("copy");
        const modalInit = Modal.getInstance(document.getElementById('id_modal_saved_search') as HTMLElement);
        modalInit && modalInit.hide();
        nytNotification('success', globalMessages().textCopied);
    },

    // select all funcion
    selectAll: (event) => {
        const element = event.target;
        const container = $(element).closest('.table').find('tbody :checkbox:not(:disabled)');
        if (element.checked) {
            container.each(SavedSearch.selectAllTrue);
        } else {
            container.each(SavedSearch.selectAllFalse);
        }
    },

    // function for each selectAll
    selectAllTrue: (i, element) => {
        element.checked = true;
        SavedSearch.addCheckRow(element);
    },
    selectAllFalse: (i, element) => {
        element.checked = false;
        SavedSearch.removeCheckRow(element);
    },

    addCheckRow: (el) => {
        $(el).closest('tr').addClass('checked');
    },
    removeCheckRow: (el) => {
        $(el).closest('tr').removeClass('checked');
    },

    checkItem: (e) => {
        const element = e.target;
        if ($(element).is(":checked")) {
            SavedSearch.addCheckRow(element);
        } else {
            SavedSearch.removeCheckRow(element);
        }
    },

    getSavedSearchesLength: () => {
        const noResultsRow = $('.sec__main--saved-search .no-results'),
        savedSearches = $('.sec__main--saved-search [id^="js-saved-search-"]').length;
        if (savedSearches > 0) {
            noResultsRow.addClass('d-none');
        } else {
            noResultsRow.removeClass('d-none');
        }
    },

    getSelectedSearches: () => {
        let searchRows;
        searchRows = $('.sec__main--saved-search tbody .checked');
        let searches: string[] = [];
        $.each(searchRows, function (i, row: HTMLElement) {
            let id = $(row).attr('data-id') + '';
            searches.push(id);
        });
        return searches;
    },

    multipleDeleteSearch: () => {
        const data = {
            'search_ids': SavedSearch.getSelectedSearches(),
            'action': 'delete'
        };
        // need to prevent calls when none item selected
        const selectedInputs = $('.sec__main--saved-search .table tbody .checked').length;
        $.ajax({
            url: '/saved-search/',
            type: 'POST',
            data: JSON.stringify(data),
            success: function (response) {
                SavedSearch.multipleDeleteSearchSuccess(response, data, selectedInputs)
            },
            error: function () {
                nytNotification('error', globalMessages().savedSearchDeletedError);
            }
        });
    },

    multipleDeleteSearchSuccess: (response, data, selectedInputs) => {
        if (response.success) {
            $.each(data.search_ids, function (i, id) {
                $('#js-saved-search-' + id).remove();
                $('#js-saved-search-navlink-' + id).remove();
            });
            // Update header navbar saved searches
            const savedSearchElement = $('.js-count-saved-search');
            const count = parseInt(savedSearchElement.html(), 10);
            savedSearchElement.html((count - selectedInputs) + '');
            nytNotification('success', globalMessages().multipleSavedSearchDeleted);
            SavedSearch.getSavedSearchesLength();
        }
    }

};

export default SavedSearch;
