import $ from 'jquery';
import { checkForm, nytConfirm } from '../globalFunctions';
import globalMessages from '../globalMessages';
import { TomInput } from 'tom-select/dist/types/types';
import TomSelect from 'tom-select';

let validationOptions = {
    'issues-0-infringement_url': globalMessages().infringementURL,
    'issues-0-file': globalMessages().infringementFile
};

var InfringementForm = {
    init: () => {
        $('#id-infringement-form').on('change', 'input[type=file]', InfringementForm.addNewFile);
        $('#id-infringement-form .js-add-issue').on('click', InfringementForm.addNewIssue);
        $('#id-infringement-form .js-remove-issue').on('click', InfringementForm.removeIssue);
        $('#id-infringement-form').on('submit', InfringementForm.post);
    },

    addNewFile: (e) => {
        const input = $(e.currentTarget),
        inputOrder = input.attr('id')!.match(/\d+/)![0] || '0',
        files = input[0].files,
        infringementURL = $(`#id_issues-${inputOrder}-infringement_url`),
        nytURL = $(`#id_issues-${inputOrder}-original_url`);
        let result = '';

        if (files.length) {
            $.each(files, (i, file) => {
                result += '<div>' + file.name + '</div>';
            });
            // when file is attached, infridgement url and nyt url fields are not required
            infringementURL.addClass('no-validate');
            nytURL.addClass('no-validate');
        } else {
            infringementURL.removeClass('no-validate');
            nytURL.removeClass('no-validate');
        }
        input.siblings('.upload-files').html(result);
    },

    addNewIssue: (e) => {
        e.preventDefault();
        const firstSelect = document.querySelector('#id-infringement-form .js-issue .js-select select');
        (firstSelect as TomInput).tomselect?.destroy();

        const newElement = document.querySelector('#id-infringement-form .js-issue')?.cloneNode(true) as Element,

        lastIssue = Array.from(document.querySelectorAll('#id-infringement-form .js-issue')).pop(),
        totalIssues = document.getElementById('id_issues-TOTAL_FORMS') as HTMLInputElement;
        let total = parseInt(totalIssues.value);
        newElement?.querySelectorAll('.has-error').forEach(el => el.classList.remove('has-error'));
        newElement?.querySelectorAll('input:not(.items-placeholder):not(.dropdown-input), select').forEach((el) => {
            let name = el.getAttribute('name')!.replace('-0-', '-' + total + '-');
            let id = 'id_' + name;
            el.setAttribute('name', name);
            el.setAttribute('id', id);
            (el as HTMLInputElement|HTMLSelectElement).value = '';

            let errorSpan = el?.closest('.form-group')?.querySelector('.error');
            if (errorSpan) {
                errorSpan.innerHTML = '';
                let newClass = errorSpan.getAttribute('class')!.split(/\s+/)[1].replace('-0-', '-' + total + '-');
                errorSpan.removeAttribute('class');
                errorSpan.classList.add(`error`);
                errorSpan.classList.add(newClass);
            }
        });

        newElement.querySelectorAll('label').forEach((el) => {
            let newFor = el.getAttribute('for')!.replace('-0-', '-' + total + '-');
            el.setAttribute('for', newFor);
        });

        const uploadText = newElement.querySelector('.upload-container .upload-files') as HTMLElement;
        uploadText.innerHTML = '';
        total++;
        totalIssues.value = String(total);
        newElement!.insertAdjacentHTML('afterbegin', '<div class="divider"></div><div class="text-right marg-bot-16"><button type="button" class="btn btn-black js-remove-issue">Remove Issue</button></div>');
        newElement?.querySelector('.js-remove-issue')?.addEventListener('click', InfringementForm.removeIssue);
        newElement?.querySelector('.js-infringement-url-input')?.classList.remove('no-validate');
        newElement?.querySelector('input[type="file"]')?.classList.remove('no-validate');
        lastIssue!.after(newElement);

        // init tom select for new added issue
        const newLastIssueSelect = Array.from(document.querySelectorAll('#id-infringement-form .js-issue')).pop()?.querySelector('select');
        new TomSelect((newLastIssueSelect as HTMLSelectElement), {plugins: ['dropdown_input']});

        InfringementForm.createValidateOptions(total);
    },

    removeIssue: (e) => {
        e.preventDefault();
        const issue = $(e.currentTarget).closest('.js-issue');
        nytConfirm(globalMessages().removeInfringement, {
            yesText: 'Yes',
            onConfirm: function () {
                issue.remove();
            }
        });
    },

    post: (e) => {
        e.preventDefault();
        const form = e.currentTarget,
        infringementURLInput = document.querySelectorAll('.js-infringement-url-input');
        // remove validation for file input when infringement url field is filled
        infringementURLInput.forEach(input => {
            let fileInput = input.closest('.js-issue')?.querySelector('input[type="file"]');
            if((<HTMLInputElement>input).value !== '') {
                fileInput?.classList.add('no-validate');
            } else {
                fileInput?.classList.remove('no-validate');
            }
        });

        if(checkForm(form, validationOptions)) {
            form.submit();
        }
    },

    createValidateOptions: (nr: number) => {
        for (let i = 0; i < nr; i++) {
            const keyPrefix = `issues-${i}-`;
            validationOptions[`${keyPrefix}infringement_url`] = globalMessages().infringementURL;
            validationOptions[`${keyPrefix}file`] = globalMessages().infringementFile;
        }
    }
}

export default InfringementForm;
