import $ from 'jquery';
import { Popover } from 'bootstrap';
import ContentInquiryForm from './ContentInquiryForm';
import CollectionModal from './CollectionModal';
import SliderCategory from './SliderCategory';
import SlickCarousel from './SlickCarousel';

const RelatedContent = {
    init: () => {
        if ($('#related-content-details').length) {
            RelatedContent.addRelatedContent();
        }
        // pause videos when slider change
        $('.js-gallery-slider').on('beforeChange', RelatedContent.videoPause);
        // pause player if related gallery modal is hiding
        document.getElementById('content-gallery-modal')?.addEventListener('hide.bs.modal', RelatedContent.videoPause);

        $('.sec__main--content__wrapper aside').on('mouseenter', RelatedContent.sidebarScrollIn);
        $('.sec__main--content__wrapper aside').on('mouseleave', RelatedContent.sidebarScrollOut);
    },

    sidebarScrollIn: () => {
        const sidebar = <HTMLElement>document.querySelector('.sec__main--content__wrapper aside')!,
        sidebarPosition = sidebar.offsetTop,
        sidebarHeight = sidebar.offsetHeight,
        sidebarBottom = sidebarPosition + sidebarHeight,
        content = <HTMLElement>document.querySelector('.text-copied-to-clipboard')!,
        contentContainer = <HTMLElement>document.querySelector('.article-content-details')!,
        contentPosition = contentContainer.offsetTop,
        contentHeight = content.offsetHeight,
        contentBottom = content.offsetTop + content.offsetHeight;
                
        // make sidebar scrollable only when is not at top or bottom
        // sidebar not at top && content smaller than sidebar && sidebar at bottom && sidebar not at same position like content
        if((sidebarPosition > 0) && (contentHeight > sidebarHeight) && (sidebarBottom + 60 < contentBottom) && sidebarPosition != contentPosition) {
            sidebar.classList.add('side-scroll');
        }
    },
    sidebarScrollOut: () => {
        const sidebar = $('.sec__main--content__wrapper aside');
        sidebar.animate({
                scrollTop: 0
            }, 100, 
            () => {
                sidebar.removeClass('side-scroll');
            }
        );
    },

    // stop playing or buffering players when close modal or change slide
    videoPause: () => {
        const allVideosPlayers = $('.jwplayer');
        // check if video player exist
        if (allVideosPlayers.length) {
            $.each(allVideosPlayers, (i, el) => {
                const playerIndentificator = $(el).attr('id');
                if(window.jwplayer(playerIndentificator).getState() === 'playing' || window.jwplayer(playerIndentificator).getState() === 'buffering') {
                    window.jwplayer(playerIndentificator).pause();
                }
            });
        }
        // hide popover when slider is moved
        const popoverTriggerList = [].slice.call(document.querySelectorAll('.btn-collection-popover'))
        const popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
            let popOver = Popover.getInstance(popoverTriggerEl);
            popOver && popOver.hide()
        })
    },

    addRelatedContent: () => {
        const element = $('#related-content-details');
        const reqObject = {
            'item_id': element.attr('data-id'),
            'item_type': element.attr('data-type'),
            'url': document.referrer
        };

        $.ajax({
            url: '/populate-related/',
            type: 'GET',
            data: reqObject,
            beforeSend: loadStart,
            complete: loadStop,
            success: function (response) {
                RelatedContent.addRelatedContentSuccess(response);
                ContentInquiryForm.init();
                CollectionModal.init();
            }
        });

        function loadStart() {
            $('.sec__main--feeds-categories-loading-icon .js-loading').css("display", "block");
        }

        function loadStop() {
            $('.sec__main--feeds-categories-loading-icon .js-loading').css("display", "none");
            // fix for card title line-clamp (it's a Chrome bug, but now we have a workaround until Chrome will fix that)
            $('.sec__main--my-feeds article .article-title').addClass('full-width');
        }
    },

    addRelatedContentSuccess: (response) => {
        const results = response.results;
        const view_all_link = response.url;
        const static_url = response.static_url;

        const RelatedContentSlider = new SliderCategory();
        const RelatedContentTitle = 'You may also be interested in';

        RelatedContentSlider.initTemplates();
        RelatedContentSlider.renderCategory(RelatedContentTitle, results, true, view_all_link, static_url, response.show_contact_rep, response.download_only);

        $.each($('.slider-init'), function (index, item) {
            $(item).addClass('slider-' + index);
            SlickCarousel.init('.slider-init', item);
        });
    }
};

export default RelatedContent;
