import $ from 'jquery';
import globalMessages from '../globalMessages';
import {nytConfirm, nytNotification} from '../globalFunctions';

// $ is the export of the jquery module.
var UserManage = {
    init: () => {
        $('.select-all').on('click', UserManage.selectAll);
        $('.sec__main--profile-content tbody :checkbox').on('change', UserManage.checkItem);
        $('.sec__main--profile-content .dropdown-menu a').on('click', UserManage.changeState);
        UserManage.getUsersLength();
    },

    getUsersLength: () => {
        const noResultsRow = $('.sec__main--profile-content .no-results'),
        usersRows = $('.sec__main--profile-content tbody tr:not(.no-results)').length;
        if (usersRows > 0) {
            noResultsRow.addClass('d-none');
        } else {
            noResultsRow.removeClass('d-none');
        }
    },

    // select all funcion
    selectAll: (e) => {
        const element = e.target;
        const container = $(element).closest('.table').find('tbody :checkbox:not(:disabled)');
        if (element.checked) {
            container.each(UserManage.selectAllTrue);
        } else {
            container.each(UserManage.selectAllFalse);
        }
    },

    // function for each selectAll
    selectAllTrue: (i, element) => {
        element.checked = true;
        UserManage.addCheckRow(element);
    },
    selectAllFalse: (i, element) => {
        element.checked = false;
        UserManage.removeCheckRow(element);
    },

    addCheckRow: (el) => {
        $(el).closest('tr').addClass('checked');
    },
    removeCheckRow: (el) => {
        $(el).closest('tr').removeClass('checked');
    },

    checkItem: (e) => {
        const element = e.target;
        if ($(element).is(":checked")) {
            UserManage.addCheckRow(element);
        } else {
            UserManage.removeCheckRow(element);
        }
    },

    // dropdown selection posts
    changeState: (e) => {
        e.preventDefault();
        const element = e.target;
        // check for bulk action
        const bulkAction = $(element).closest('.dropdown-menu').hasClass('bulk'),
        itemVal = $(element).attr('data-value'),
        field = $(element).attr('data-field');

        if(field === 'is_archived') {
            nytConfirm("Are you sure you want to remove this user?", {
                yesText: 'Yes',
                onConfirm: function () {
                    changeStateCall();
                }
            });
        } else {
            changeStateCall();
        }

        function changeStateCall () {
            const reqObject = {
                'users': UserManage.getUsers(bulkAction, element),
                'action_field': field,
                'action_value': itemVal
            };
            const requestString = JSON.stringify(reqObject);

            // need to prevent calls when none item selected
            const selectedInputs = $('.sec__main--profile-content .table tbody .checked').length;
            if ((selectedInputs !== 0 && bulkAction) || !bulkAction) {
                $.ajax({
                    url: '/users/', // the endpoint
                    type: 'POST', // http method
                    data: requestString,
                    // handle a successful response
                    success: function (response) {
                        UserManage.changeStateSuccess(response);
                    },
                    // handle a non-successful response
                    error: function () {
                        nytNotification('error', globalMessages().genericRequestError);
                    }
                });
            }
        }
    },

    changeStateSuccess: (response) => {
        if (response.success === 'true') {
            UserManage.changeStateSuccessEach(response);
            nytNotification('success', globalMessages().userPropertyUpdate);
        } else {
            nytNotification('error', globalMessages().genericRequestError);
        }
    },

    changeStateSuccessEach: (response) => {
        $.each(response.data.users, (i, user) => {
            var userRow = $('.sec__main--profile-content tbody tr[data-id="' + user.id + '"]');
            if (response.data.action_field === "is_active") {
                userRow.find('.drop-val[data-field="' + response.data.action_field + '"]').text(response.data.action_value === 'true' ? 'Active' : 'Inactive');
            } else if (response.data.action_field === "groups") {
                userRow.find('.drop-val[data-field="' + response.data.action_field + '"]').text(
                    response.data.action_value === 'Account holder' ? 'Administrator' : response.data.action_value
                );
            } else if (response.data.action_field === 'is_archived') {
                userRow.remove();
                UserManage.getUsersLength();
            } else {
                userRow.find('.drop-val[data-field="' + response.data.action_field + '"]').text(response.data.action_value);
            }
        });
    },

    getUsers: (bulk, item) => {
        var userRows;
        if (bulk) {
            userRows = $('.sec__main--profile-content tbody .checked');
        } else {
            userRows = $(item).closest('tr');
        }
        let user = {},
        users: object[] = [];
        $.each(userRows, function (i, row) {
            user = {
                'id': $(row).attr('data-id'),
                'first_name': $(row).find('.fname').text(),
                'last_name': $(row).find('.lname').text(),
                'email': $(row).find('.email').text(),
            };
            users.push(user);
        });
        return users;
    },



};

export default UserManage;
