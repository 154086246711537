import $ from 'jquery';
import { Modal } from 'bootstrap';
import globalMessages from '../globalMessages';
import {nytNotification, checkForm} from '../globalFunctions';

// $ is the export of the jquery module.
let ids : string[] = [];
const BillingForm = {
    init: () => {
        (<any>$('#manage-autopay-form .autopay-date')).datetimepicker({
            timepicker: false,
            format: 'F d, Y',
            value: new Date(),
            allowBlank: false,
            scrollInput: false,
            minDate: new Date()
        });
        document.querySelectorAll('.sec__main--billing-page .notification .toggle-input').forEach(input => input.addEventListener('change', BillingForm.notificationToggle));
        document.getElementById('manage-autopay-form')?.addEventListener('submit', BillingForm.validateForm);
        document.querySelector('.select-all')?.addEventListener('click', BillingForm.selectAll);
        document.querySelector('.js-bulk-pay')?.addEventListener('click', BillingForm.bulkPay);
        document.querySelector('.js-bulk-download')?.addEventListener('click', BillingForm.bulkDownload);
        document.querySelector('.reset-filter a')?.addEventListener('click', BillingForm.resetFilter);
        document.getElementById('billing-address')?.addEventListener('submit', BillingForm.validation);
        document.getElementById('billing-address')?.querySelectorAll('.form-check-input').forEach(input => input.addEventListener('change', BillingForm.radioSelect));
        BillingForm.launchModal();
        document.getElementById('launch-modal')?.addEventListener('hidden.bs.modal', BillingForm.launchModalClose);
    },

    notificationToggle: (e) => {
        const input = e.currentTarget,
        value = input.checked ? true : false,
        action = input.getAttribute('data-action'),
        dataForm = {
            action: action,
            value: value
        };

        $.ajax({
            url: '/billing/email-notifications/',
            type: 'POST',
            data: dataForm,
            success: function (response) {
                if (response.success) {
                    nytNotification('success', globalMessages().preferencesSaved);
                }
            },
            error: function () {
                nytNotification('error', globalMessages().genericRequestError);
            }
        });

    },

    validateForm: (e) => {
        e.preventDefault();
        const form = e.target;
        if (checkForm(form)) {
            form.submit();
        }
    },

    validation: (e) => {
        e.preventDefault();
        const form = e.target,
        continueBtnAction = document.querySelector('.js-billing-pay-continue')?.getAttribute('data-action'),
        countrySelect = form.querySelector('.js-country select') as HTMLSelectElement,
        countrySelectText = countrySelect.options[countrySelect.selectedIndex].text;
        if (checkForm(form)) {
            BillingForm.interstitial({type: 'form', target: form});
            // event tracking
            if (continueBtnAction === 'Pay') {
                syn4('pay', {'page_url': 'Portal Continue', 'name': 'Invoice Continue'});
            } else {
                syn4('pay', {'page_url': 'Portal Continue', 'name': 'AutoPay Continue'});
            }
        }
    },

    selectAll: (e) => {
        const element = e.target;
        const container = element.closest('.table').querySelectorAll('tbody input[type="checkbox"]:not(:disabled)');
        if (element.checked) {
            container.forEach(checkbox => checkbox.checked = true);
        } else {
            container.forEach(checkbox => checkbox.checked = false);
        }
    },

    bulkPay: (e) => {
        e.preventDefault();
        ids = [];
        const checkboxes = document.querySelectorAll('.table tbody input[type="checkbox"]:checked:not(.paid)');
        checkboxes.forEach(checkbox => {
            let tr = checkbox.closest('tr');
            ids.push(tr!.getAttribute('data-id')!);
        });        

        if (checkboxes.length) {
            $.ajax({
                url: '/billing/multiple-pay/',
                type: 'POST',
                data: {'orders': ids},
                traditional: true,
                success: (response) => {
                    if (response.success === 'true') {
                        window.location.href = response.url;
                        syn4('pay', {'page_url': 'Payment Portal', 'name': 'Pay Multiple'});
                    } else {
                        nytNotification('error', globalMessages().generalError);
                    }
                },
                error: () => {
                    nytNotification('error', globalMessages().genericRequestError);
                }
            });
        } else {
            nytNotification('error', globalMessages().bulkPay);
        }
    },

    bulkDownload: (e) => {
        e.preventDefault();
        // empty selection array to prevent double selection
        ids = [];
        const checkboxes = document.querySelectorAll('.table tbody input[type="checkbox"]:checked');
        checkboxes.forEach(checkbox => {
            let tr = checkbox.closest('tr');
            ids.push(tr!.getAttribute('data-id')!);
        });
        const type = e.currentTarget.getAttribute('data-type'),
        date = new Intl.DateTimeFormat('en-US').format(new Date());
        
        $.ajax({
            url: '/billing/multiple-download/',
            type: 'POST',
            data: {'orders': ids},
            traditional: true,
            xhrFields:{
                responseType: 'blob'
            },
            success: (response) => {
                if (type == 'invoices') {
                    syn4('download', {'page_url': 'Payment Portal', 'name': 'Invoice Individual Download', 'click_text': 'Invoice Bulk'});
                } else {
                    syn4('download', {'page_url': 'Payment Portal', 'name': 'Statement Bulk Download', 'click_text': 'Statement Bulk'});
                }
                const link = document.createElement('a'),
                linkHref = window.URL.createObjectURL(response);
                link.href = linkHref;
                link.download = `${type}-${date}.zip`;
                document.body.appendChild(link);
                link.click();
                window.URL.revokeObjectURL(linkHref);
            },
            error: () => {
                nytNotification('error', globalMessages().genericRequestError);
            }
        });
    },

    resetFilter: (e) => {
        e.preventDefault();
        const selects = e.target.closest('form').querySelectorAll('select');
        selects.forEach(el => {
            el.selectedIndex = 0
            el.dispatchEvent(new Event('change'));
        });
        (<HTMLElement>document.querySelector('.filter-buttons .filter')).click();
    },
    // options = {type: a/form/function, target: redirect to link/submit a form/call a function}
    interstitial: (options) => {
        const interstitial = document.getElementById('interstitial') as HTMLElement,
        interstitialModal = new Modal(interstitial),
        openModalFlag = document.querySelector('.modal.show');
        if(openModalFlag) {
            const modalID:string = openModalFlag.getAttribute('id')!,
            openModal = Modal.getInstance(document.getElementById(modalID) as HTMLElement);
            openModal && openModal.hide();
        }
        interstitialModal && interstitialModal.show();
        // set 5s timeout for redirect
        let timeout = setTimeout(() => {
            if (options.type === 'function') {
                options.function();
            } else if (options.type === 'a') {                
                window.location.href = options.target;
            } else if (options.type === 'form') {
                options.target.submit();
            }
        }, 5000);
        // cancel redirecting when close the modal
        interstitial.addEventListener('hidden.bs.modal', () => {
            clearTimeout(timeout);
        }, {once: true});

        interstitial.querySelector('.js-interstitial-link')!.addEventListener('click', (e) => {
            e.preventDefault();
            if (options.type === 'function') {
                options.function();
            } else if (options.type === 'a') {
                window.location.href = options.target;
            } else if (options.type === 'form') {
                options.target.submit();
            }
            clearTimeout(timeout);
        }, {once: true});
    },

    radioSelect: (e) => {
        const billingContainer = document.getElementById('billing-address')?.querySelector('.js-card-only');
        
        if (e.target.checked && e.target.value == 'ach') {
            billingContainer?.classList.add('d-none');
            billingContainer?.querySelectorAll('input, select').forEach(input => input.setAttribute('disabled', 'disabled'));
        } else {
            billingContainer?.classList.remove('d-none');
            billingContainer?.querySelectorAll('input, select').forEach(input => input.removeAttribute('disabled'));
        }
    },
    
    launchModal: () => {
        const launchSelector = document.getElementById('launch-modal') as HTMLElement;
        if (launchSelector) {
            const launchModal = new Modal(launchSelector);
            launchModal?.show();
        }
    },

    launchModalClose: () => {
        $.ajax({
            url: '/billing/hide-message/',
            type: 'POST',
            data: JSON.stringify({'opt-in': true}),
            success: (response) => {
                if (!response.success) {
                    nytNotification('error', globalMessages().genericRequestError);
                }
            },
            error: () => {
                nytNotification('error', globalMessages().genericRequestError);
            }
        });
    }
}
export default BillingForm;
