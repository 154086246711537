import $ from 'jquery';
import {Modal} from 'bootstrap';
import globalMessages from '../globalMessages';
import {nytNotification, copy2clipboard} from '../globalFunctions';

var ContentShareForm = {
    init: () => {
        $('#share-article-btn').on('click', ContentShareForm.getShareLink);
        $('#js-share-link-copy').on('click', ContentShareForm.copyShareLink);
    },

    getShareLink: (e) => {
        const shareBtn = $(e.target),
        dataForm = $('#share-article-form').serializeForm();
        shareBtn.addClass('loading');
        $.ajax({
            url: '/preview/',
            type: 'POST',
            data: JSON.stringify(dataForm),
            success: (response) => {
                ContentShareForm.getShareLinkSuccess(response);
            },
            error: () => {
                nytNotification('error', globalMessages().genericRequestError);
                shareBtn.removeClass('loading');
            }
        });
    },

    getShareLinkSuccess: (response) => {
        $('#share-article-btn').removeClass('loading');
        if (response.success) {
            $('#js-share-link').val(response.url);
            const modalInit = new Modal(document.getElementById('share-article') as HTMLElement);
            modalInit && modalInit.show();
        } else {
            nytNotification('error', globalMessages().genericRequestError);
        }
    },

    copyShareLink: (e) => {
        e.preventDefault();
        copy2clipboard('js-share-link');
    }
};

export default ContentShareForm;
