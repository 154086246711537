import BaseView from './BaseView';
import CsrfTokenSetup from '../services/CsrfTokenSetup';
import UserCollections from '../services/UserCollections';

function UserCollectionsView(this: any, options) {
    this.$el = BaseView.initView(options);

    this.init = function () {
        CsrfTokenSetup.init();
        UserCollections.init();
    };

    this.init();
}
export default UserCollectionsView;