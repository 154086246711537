import $ from 'jquery';
import { Modal } from 'bootstrap';
import {setCookie, checkForm, nytNotification, nytConfirm} from '../globalFunctions';
import globalMessages from '../globalMessages';
import UserCollections from '../services/UserCollections';

const UserCollectionItem = {
    init: () => {
        $('.sec__main--collection-item .delete-collection').on('click', UserCollectionItem.initDeleteModal);
        $('.sec__main--collection-item .delete-item').on('click', UserCollectionItem.initDeleteItemModal);
        $('.sec__main--collection-item .delete-item-all').on('click', UserCollectionItem.initDeleteItemAllModal);
        $('.sec__main--collection-item .rename-collection').on('click', UserCollections.populateCollectionID);
        $('.sec__main--collection-item .share-collection').on('click', UserCollections.shareCollection);
        $('#js-share-link-copy').on('click', UserCollections.copyShareLink);
        $('#js-confirm-collection-rename').on('click', UserCollectionItem.renameCollection);
        $('.sec__main--collection-item .change-list').on('click', UserCollectionItem.changeView);
    },

    renameCollection: (e) => {
        e.preventDefault();
        const form = e.target.closest('form');
        if (checkForm(form)) {
            UserCollectionItem.renameCollectionPost(form);
        }
    },

    renameCollectionPost: (form) => {
        const data = $(form).serializeForm(),
            collectionID = $('#js-collection-id').val();
        data.collection_ids = [collectionID];
        data.action = 'rename';
        $.ajax({
            url: '/collections/',
            type: 'POST',
            data: JSON.stringify(data),
            success: function (response) {
                UserCollectionItem.renameCollectionPostSuccess(response, form);
            },
            error: function () {
                nytNotification('error', globalMessages().genericRequestError);
            }
        });
    },

    renameCollectionPostSuccess: (response, form) => {
        if(response.success) {
            const modalInit = Modal.getInstance(document.getElementById('id_modal_confirmation_rename') as HTMLElement);
            nytNotification('success', globalMessages().collectionRenameSuccess);
            $('.collection-name-' + response.data.collection_ids).text(response.data.value);
            $('.collection-name-' + response.data.collection_ids).attr('data-count', ' ('+ response.items_count +')');
            $('#js-collection-navlink-' + response.data.collection_ids + ' a').text(response.data.value);
            modalInit && modalInit.hide();
            
            $('.rename-collection').attr('data-title', response.data.value);
            $('.delete-collection').attr('data-title', response.data.value);
            document.title = response.data.value;
        } else {
            $(form).find('.error.value')
                .html(response.error)
                .closest('.form-group').addClass('has-error');
        }
    },

    initDeleteModal: (event) => {
        event.preventDefault();
        const element = event.target,
            collectionTitle = (($(element).attr('data-title')) + '').replace(/<\/?[^>]+(>|$)/g, ""),
            collectionId = $(element).attr('data-id'),
            message = 'the collection <strong>' + collectionTitle + '</strong>';

        nytConfirm("You're about to delete " + message + " from your profile. Please confirm.", {
            yesText: 'Delete',
            noText: 'Cancel',
            onConfirm: function () {
                UserCollectionItem.deleteCollection(collectionId);
            }
        });
    },

    deleteCollection: (collectionId) => {
        const data = {
            action: 'delete',
            collection_ids: [collectionId]
        };

        $.ajax({
            url: '/collections/',
            type: 'POST',
            data: JSON.stringify(data),
            success: function () {
                window.location.href = "/collections/"; // redirect to collections page
            },
            error: function () {
                nytNotification('error', globalMessages().genericRequestError);
            }
        });
    },

    initDeleteItemModal: (event) => {
        event.preventDefault();
        const element = event.target,
            article = $(element).closest('.item'),
            itemTitle = (($(element).attr('data-title')) + '').replace(/<\/?[^>]+(>|$)/g, ""),
            itemId = $(element).attr('data-id'),
            collectionId = $(element).attr('data-collection'),
            message = 'the item <strong>' + itemTitle + '</strong>';

        nytConfirm("You're about to remove " + message + " from this collection. Please confirm.", {
            yesText: 'Remove',
            noText: 'Cancel',
            onConfirm: function () {
                UserCollectionItem.deleteCollectionItem(itemId, collectionId, article, itemTitle);
            }
        });
    },

    deleteCollectionItem: (itemId, collectionId, article, itemTitle) => {
        const data = {
            action: 'delete',
            item_ids: [itemId],
            collection: collectionId
        };

        $.ajax({
            url: '/collection/' + collectionId + '/',
            type: 'POST',
            data: JSON.stringify(data),
            success: function (response) {
                UserCollectionItem.deleteCollectionItemSuccess(response, article, itemTitle);
            },
            error: function () {
                nytNotification('error', globalMessages().genericRequestError);
            }
        });
    },

    deleteCollectionItemSuccess: (response, article, itemTitle) => {
        if(response.success) {
            nytNotification('success', globalMessages().collectionItemDeleteSuccess);
            article.remove();
            $('.download-item-all').attr('href', response.download_url);
            if ($('.download-item-all').hasClass('disabled')) {$('.download-item-all').removeClass('disabled');}
            $('.collection-name-' + response.data.collection).text(response.collection_name);
            $('.collection-name-' + response.data.collection).attr('data-count', ' ('+ response.items_count +')');
            UserCollectionItem.checkCollectionsLength();
        } else {
            nytNotification('error', response.error);
        }
    },

    initDeleteItemAllModal: (event) => {
        event.preventDefault();
        const articles = $('.articles .item'),
            collectionId = $(event.target).attr('data-collection');

        nytConfirm("You're about to remove <strong>all assets</strong> from this collection. Please confirm.", {
            yesText: 'Remove',
            noText: 'Cancel',
            onConfirm: function () {
                UserCollectionItem.deleteCollectionItemAll(collectionId, articles);
            }
        });
    },

    deleteCollectionItemAll: (collectionId, articles) => {
        const data = {
            action: 'deleteAll',
            item_ids: [],
            collection: collectionId
        };

        $.ajax({
            url: '/collection/' + collectionId + '/',
            type: 'POST',
            data: JSON.stringify(data),
            success: function (response) {
                UserCollectionItem.deleteCollectionItemAllSuccess(response, articles)
            },
            error: function () {
                nytNotification('error', globalMessages().genericRequestError);
            }
        });
    },

    deleteCollectionItemAllSuccess: (response, articles) => {
        if (response.success) {
            const collection_name = $('.page-title.collection-name-' + response.data.collection).text();
            nytNotification('success', globalMessages().collectionItemDeleteAllSuccess);
            articles.remove();
            $('.download-item-all').attr('href', response.download_url);
            $('.collection-name-' + response.data.collection).attr('data-count', ' ('+ response.items_count +')');
            UserCollectionItem.checkCollectionsLength();
        } else {
            nytNotification('error', response.error);
        }
    },

    changeView: (event) => {        
        event.preventDefault();
        location.reload();
        const type = $(event.currentTarget).attr('data-view') || '';
        setCookie('view-mode', type, 365);
    },

    checkCollectionsLength: () => {
        const collectionContainer = document.querySelector('.sec__main--collection-item'),
        articlesLength = collectionContainer?.querySelectorAll('.articles .item').length;
        if(!articlesLength) {
            collectionContainer?.querySelectorAll('.js-disable')?.forEach(el => el.classList.add('disabled'));
            collectionContainer?.querySelectorAll('.helper-text')?.forEach(el => el.classList.remove('d-none'));
            collectionContainer?.querySelectorAll('.js-delete')?.forEach(el => el.remove());
        }
    }
};

export default UserCollectionItem;
