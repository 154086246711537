import $ from 'jquery';
import BaseView from './BaseView';
import CartCheckout from '../services/CartCheckout';

function CartView (this: any, options) {
    this.$el = BaseView.initView(options);

    // change window title when change tab to remember
    this.changeTitle = () => {
        var original,
            message = "Don't forget to complete your order",
            emptyMsg = 'Your cart is empty',
            finishMsg = 'Thank you for your order',
            title = document.title;
        // prevent page title change when cart is empty or order is finished
        if (title !== emptyMsg && title !== finishMsg) {
            $(window).focus(function() {
                if (original) {
                    document.title = original;
                }
            }).blur(function() {
                if (title !== message) {
                    original = title;
                }
                document.title = message;
            });
        }
    };

    this.init = () => {
        CartCheckout.init();
        this.changeTitle();
    };
    this.init();
}

export default CartView;
