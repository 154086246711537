import $ from 'jquery';
import BaseView from './BaseView';
import CsrfTokenSetup from '../services/CsrfTokenSetup';
import BillingForm from '../services/BillingForm';

function BillingView (this: any, options) {
    this.$el = BaseView.initView(options);

    this.init = function () {
        CsrfTokenSetup.init();
        BillingForm.init();
    };

    this.init();
}
export default BillingView;
