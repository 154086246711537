import {checkForm, nytNotification, setCookie} from '../globalFunctions';
import globalMessages from '../globalMessages';

const Homepage = {
    init: () => {
        document.getElementById('js-banner-close')?.addEventListener('click', Homepage.hideBanner);
    },
    
    hideBanner: (e) => {
        e.preventDefault();
        setCookie('wban', 'hide', e.target.getAttribute('data-postpone'));
        document.querySelector('.sec__main--homepage-banner')?.classList.add('fade-out');
    }
};

export default Homepage;
