import BaseView from './BaseView';
import {getCookie, setCookie} from '../globalFunctions';
import CsrfTokenSetup from '../services/CsrfTokenSetup';
import UserCollectionItem from '../services/UserCollectionItem';

function UserCollectionItemView(this: any, options) {
    this.$el = BaseView.initView(options);
    this.ViewModeCollection = () => {
        const type = localStorage.getItem('ViewModeCollection');
        if (type === 'list') {
            $('.collection-item-actions .change-list.' + type).trigger('click');
        } else {
            localStorage.setItem('ViewModeCollection', 'grid');
        }
    };

    this.changeToGrid = function () {
        if (window.innerWidth <= 768) {
            // display only grid view for mobile 
            const viewMode = getCookie('view-mode');
            if (viewMode && viewMode != 'grid') {
                setCookie('view-mode', 'grid', 365);
                window.location.reload();
            }
        }
    };

    this.init = function () {
        this.changeToGrid();
        CsrfTokenSetup.init();
        UserCollectionItem.init();
        this.ViewModeCollection();
    };

    this.init();
}
export default UserCollectionItemView;
