import BaseView from './BaseView';
import CsrfTokenSetup from '../services/CsrfTokenSetup';
import ForgotPasswordForm from '../services/ForgotPasswordForm';

function ForgotPasswordView (this: any, options) {
    this.$el = BaseView.initView(options);

    this.init = function () {
        CsrfTokenSetup.init();
        ForgotPasswordForm.init();
    };

    this.init();
}
export default ForgotPasswordView;
