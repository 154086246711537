import {copy2clipboard} from '../globalFunctions';

const GamesResources = {
    init: () => {
        document.querySelector('.copy-container-cta')?.addEventListener('click', GamesResources.copyApi);
    },

    copyApi: (e) => {
        e.preventDefault();
        copy2clipboard('js-copy-api');
    }
};

export default GamesResources;