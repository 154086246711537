import $ from 'jquery';
import globalMessages from '../globalMessages';
import {nytNotification, checkForm} from '../globalFunctions';

// $ is the export of the jquery module.
var ForgotPasswordForm = {
    init: function () {
        $('#id-forgot-password-form').on('submit', ForgotPasswordForm.event);
    },

    event: function (event) {
        event.preventDefault();
        const form = event.target;
        if(checkForm(form)) {
            ForgotPasswordForm.post();
        }
    },

    post: function () {
        $('.error.forgot-password').empty();
        $.ajax({
            url: '/forgot-password/', // the endpoint
            type: 'POST', // http method
            data: {
                email: $('#forgot-password--email').val()
            },
            // handle a successful response
            success: function (response) {
                ForgotPasswordForm.successFunction(response)
            },
            // handle a non-successful response
            error: function () {
                nytNotification('error', globalMessages().genericRequestError);
            }
        });
    },
    successFunction: function (response) {
        if (response.success === 'true') {
            ($('#id-forgot-password-form')[0] as HTMLFormElement).reset();
            // update the page title, and the main message
            window.top!.document.title = globalMessages().titleAfterResetPasswordLinkSent;
            nytNotification('success', globalMessages().successResetPasswordLinkSent + response.email + '.');
        } else {
            $.each(response.errors, function (id: string, error: string) {
                if (id === '__all__') {  // case for invalid login - generic message.
                    nytNotification('error', error);
                }
                $('.error.forgot-password.' + id)
                    .html(error)
                    .parent().addClass('has-error');
            });
        }
    }
};

export default ForgotPasswordForm;
