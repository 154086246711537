import {checkForm, formSerialize} from '../globalFunctions';
import TimesDigest from './TimesDigest';


const BSL = {
    init: () => {
        document.getElementById('id-registration-form')?.addEventListener('submit', BSL.validateRegister);
        document.getElementById('order-form-submit')?.addEventListener('click', TimesDigest.validateForm);
    },

    validateRegister: (e) => {
        e.preventDefault();
        const form = e.currentTarget;
        if(checkForm(form)) {
            form.submit();
        }
    }
};

export default BSL;