import BaseView from './BaseView';
import Homepage from '../services/Homepage';

function HomePageView(this: any, options) {
    this.$el = BaseView.initView(options);

    this.init = function () {
        Homepage.init();
    };

    this.init();
}

export default HomePageView;
