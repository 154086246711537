import $ from 'jquery';
import { Modal } from 'bootstrap';
import globalMessages from '../globalMessages';
import {nytNotification, getCookie, setCookie, checkForm} from '../globalFunctions';

var ManagedModal = {
    init: function () {
        ManagedModal.newsletterCheck();
        document.getElementById('managed-modal')?.addEventListener('hide.bs.modal', ManagedModal.postponeDisplay);
        $('#managed-modal .sec__main--managed-modal-no-thanks').on('click', ManagedModal.noThanks);
        $('#newsletter-subscribe').on('submit', ManagedModal.post);
    },

    newsletterCheck: () => {
        // newsletter modal show
        const cookieVisit = getCookie('visit'),
        managedModal = $("#managed-modal");
        if (cookieVisit && ((cookieVisit == '1') || (cookieVisit == '2'))) {
            // set cookie for second visit
            setCookie('visit', '2', 365);
            if (managedModal.length) {
                ManagedModal.initScrollModal();
            }
        } else if (!cookieVisit) {
            setCookie('visit', '1', 365);
        }
    },

    initScrollModal: () => {
        const managedModal = $("#managed-modal"),
        viewHeight = document.documentElement.clientHeight || 0,
        documentHeight = $(document).height() || 0,
        heightDifference = documentHeight - viewHeight,
        modalState = (managedModal.data('bs.modal') || {}).isShown,
        managedModalInit = new Modal(document.getElementById('managed-modal') as HTMLElement);

        if (heightDifference <= 0) {
            managedModalInit && managedModalInit.show();
        } else {
            $(window).on('scroll', () => {
                if (($(document).scrollTop()! > (documentHeight * 20/100)) && !modalState && (getCookie('visit') == '2')) {
                    managedModalInit && managedModalInit.show();
                }
            });
        }
    },

    postponeDisplay: () => {
        setCookie('visit', '0', 7);
    },

    noThanks: (e) => {
        e.preventDefault();
        const modalInit = Modal.getInstance(document.getElementById('managed-modal') as HTMLElement);
        modalInit && modalInit.hide();
        setCookie('visit', '0', 365);
    },

    post: function (event) {
        event.preventDefault();
        const element = event.target;
        if (checkForm(element)) {
            ManagedModal.subscribe(element);
        }
    },

    subscribe: (form) => {
        const dataForm = $(form).serializeForm();
        $(form).find('.btn').addClass('loading');
        $.ajax({
            url: '/pardot/editorial-subscribe/',
            type: 'POST',
            data: dataForm,
            success: function (response) {
                ManagedModal.subscribeSuccess(response, form);
            },
            error: function () {
                nytNotification('error', globalMessages().genericRequestError);
            }
        });
    },

    subscribeSuccess: (response, form) => {
        $(form).find('.btn').removeClass('loading');
        if (response.success) {
            const modalInit = new Modal(document.getElementById('managed-modal') as HTMLElement);
            modalInit && modalInit.hide();
            setCookie('visit', '0', 365);
            nytNotification('success', globalMessages().subscribeSuccess);
        } else {
            $.each(response.errors, function (id: string, error) {
                $('.error.' + id)
                    .html(error)
                    .closest('.form-group').addClass('has-error');
            });
        }
    }

};

export default ManagedModal;
