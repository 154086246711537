import $ from 'jquery';
import BaseView from './BaseView';
import CsrfTokenSetup from '../services/CsrfTokenSetup';
import EnrichedFeed from '../services/EnrichedFeed';
import SearchForm from '../services/SearchForm';

function EnrichedFeedView (this: any, options) {
    this.$el = BaseView.initView(options);

    this.DatePickerInit = () => {
        const _this = this;
        (<any>$('#filter_date_from')).datetimepicker({
            format:'m/d/Y',
            timepicker: false,
            yearStart: 2009,
            yearEnd: (new Date()).getFullYear() + 1,
            onShow: function () {
                this.setOptions({
                    maxDate: $('#filter_date_to').val() ? _this.formatDate($('#filter_date_to').val(), "yyyy/MM/dd") : false
                });
            },
            onClose: function () {
                if($('#filter_date_from').val() != '' || $('#filter_date_to').val() != '') {          
                    $('.js-time .selected-options').text($('#filter_date_from').val() +' - ' + $('#filter_date_to').val());
                    $('.js-time .dropdown-toggle').addClass('has-selections');
                    $('.js-time input[type="radio"]:checked').prop('checked', false);
                    $('.js-time .checked').removeClass('checked');
                    $('.js-time .selected-options-cta').removeClass('d-none');
                } else {
                    $('.js-time input.default').trigger('click');
                }
                SearchForm.countFilters();
            }
        });

        (<any>$('#filter_date_to')).datetimepicker({
            format:'m/d/Y',
            timepicker: false,
            yearStart: 2009,
            yearEnd: (new Date()).getFullYear() + 1,
            onShow: function () {
                this.setOptions({
                    minDate: $('#filter_date_from').val() ? _this.formatDate($('#filter_date_from').val(), "yyyy/MM/dd") : false
                });
            },
            onClose: function () {
                if($('#filter_date_from').val() != '' || $('#filter_date_to').val() != '') {
                    $('.js-time .selected-options').text($('#filter_date_from').val() +' - ' + $('#filter_date_to').val());
                    $('.js-time .dropdown-toggle').addClass('has-selections');
                    $('.js-time input[type="radio"]:checked').prop('checked', false);
                    $('.js-time .checked').removeClass('checked');
                } else {
                    $('.js-time input.default').trigger('click');
                }
            }
        });
    };

    this.formatDate = (time, format) => {
        const t = new Date(time);
        const tf = function(i) {
            return (i < 10 ? '0' : '') + i
        };
        return format.replace(/yyyy|MM|dd|HH|mm|ss/g, function(a) {
            switch (a) {
                case 'yyyy':
                    return tf(t.getFullYear());
                case 'MM':
                    return tf(t.getMonth() + 1);
                case 'mm':
                    return tf(t.getMinutes());
                case 'dd':
                    return tf(t.getDate());
                case 'HH':
                    return tf(t.getHours());
                case 'ss':
                    return tf(t.getSeconds());
            }
        })
    };

    this.init = function () {
        CsrfTokenSetup.init();
        EnrichedFeed.init();
        this.DatePickerInit();
    };

    this.init();
}
export default EnrichedFeedView;
