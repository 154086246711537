import $ from 'jquery';
import globalMessages from '../globalMessages';
import {nytNotification, checkForm} from '../globalFunctions';
import 'jquery-datetimepicker';
import { Modal } from 'bootstrap';
import { TomInput } from 'tom-select/dist/types/types';
import { selectCache } from '../tomSelect';

const ContactForm = {
    init: function () {
        $('#contactForm').on('submit', ContactForm.post);
        if($('#filmForm').length) {
            $('#filmForm').on('submit', ContactForm.filmPost);
            (<any>$('#filmForm .datepicker')).datetimepicker({
                timepicker: false,
                format: 'm/d/Y',
                value: new Date(),
                allowBlank: false,
                scrollInput: false,
                maxDate: new Date()
            });
        }
    },

    post: (e) => {
        e.preventDefault();
        const form = e.target;

        if (checkForm(form)) {
            ContactForm.createPost(form);
        }
    },

    createPost: (form) => {
        const dataForm = $('.form--contact').serializeForm(),
        submitBtn = $(form).find('.sec__main--contact-form-content-submit-cta');
        let language;
        if (dataForm.language) {
            language = dataForm.language;
        } else {
            submitBtn.addClass('loading').attr('disabled', 'disabled');
        }
        $.ajax({
            url: "/contact-us/",
            type: "POST",
            data: dataForm,
            success: function (response) {
                ContactForm.createPostSuccess(form, response, language);
                if (!dataForm.language) {
                    submitBtn.removeClass('loading').removeAttr('disabled');
                }
            },
            error: function () {
                if (language) {
                    nytNotification('error', globalMessages(language).genericRequestError);
                } else {
                    nytNotification('error', globalMessages().genericRequestError);
                    submitBtn.removeClass('loading').removeAttr('disabled');
                }
            }
        });
    },

    createPostSuccess: (form, response, language) => {
        $(form).find('.error').empty().closest('.form-group').removeClass('has-error');
        if (String(response.success) === 'true') {
            // reset form
            ($('#contactForm')[0] as HTMLFormElement).reset();
            // reset tomselect
            const selectItems = document.querySelectorAll('#contactForm .js-select select');
            selectItems?.forEach(el => (el as TomInput)?.tomselect?.clear());
            // display success message
            nytNotification('success', globalMessages(language).successContactForm);
            syn4('form_submit', {conversion: 'Yes', page_url: window.location.href, name: 'Contact Form'});

            // when submit is made from contact billing, hide the modal
            const billingContact = document.getElementById('billing-contact') as HTMLElement;
            if (billingContact) {
                const billingContactModal = Modal.getInstance(billingContact);
                billingContactModal && billingContactModal.hide();
            }
        } else {
            $.each(response.errors, function (id: string, error: string) {
                if(id !== 'captcha') {
                    $('.error.' + id)
                        .html(error)
                        .closest('.form-group').addClass('has-error');
                } else {
                    nytNotification('error', 'There has been an issue with your submission, Please contact nytlg-support@nytimes.com');
                }
            });
        }
    },

    filmPost: (e) => {
        e.preventDefault();
        const form = e.target;
        if (checkForm(form)) {
            ContactForm.createPostFilm(form);
        }
    },

    createPostFilm: (form) => {
        const dataForm = $('#filmForm').serializeForm();
        if (Array.isArray(dataForm.options_types)) {
            dataForm.options_types = dataForm.options_types.join();
        }
        $.ajax({
            url: "/send-film-options/",
            type: "POST",
            data: dataForm,
            success: function (response) {
                ContactForm.createPostFilmSuccess(form, response);
            },
            error: function () {
                nytNotification('error', globalMessages().genericRequestError);
            }
        });
    },

    createPostFilmSuccess: (form, response) => {
        $(form).find('.error').empty().closest('.form-group').removeClass('has-error');
        if (String(response.success) === 'true') {
            ($('#filmForm')[0] as HTMLFormElement).reset();
            selectCache.get(form).options_types.clear();
            nytNotification('success', globalMessages().filmFormSuccess);
        } else {
            $.each(response.errors, function(id: string, error) {
                $('.error.' + id)
                    .html(error)
                    .closest('.form-group').addClass('has-error');
            });
        }
    }
};

export default ContactForm;
