import BaseView from './BaseView';
import BSL from '../services/BSL';

function BSLView (this: any, options) {  
    this.$el = BaseView.initView(options);

    this.init = function () {
        BSL.init();
    };

    this.init();
}
export default BSLView;