import $ from 'jquery';
import { Modal } from 'bootstrap';
import globalMessages from '../globalMessages';
import SavedSearch from './SavedSearch';
import {nytConfirm, nytNotification, copy2clipboard, checkForm} from '../globalFunctions';

const UserCollections = {
    init: () => {
        $('#js-confirm-collection-rename').on('click', UserCollections.renameCollection);
        $('.sec__main--collections .delete').on('click', UserCollections.initDeleteModal);
        $('.sec__main--collections .rename').on('click', UserCollections.populateCollectionID);
        $('.sec__main--collections .share').on('click', UserCollections.shareCollection);
        $(".sec__main--collections tbody :checkbox").on('change', SavedSearch.checkItem);
        $('#js-share-link-copy').on('click', UserCollections.copyShareLink);
        $('.select-all').on('click', SavedSearch.selectAll);
    },

    populateCollectionID: (e) => {
        const element = $(e.target);
        $('#js-collection-id').val(element.attr('data-id') || '');
        $('#js-update-collection-name').val(element.attr('data-title') || '');
    },

    renameCollection: (e) => {
        e.preventDefault();
        const form = e.target.closest('form');       
        if (checkForm(form)) {
            UserCollections.renameCollectionPost(form);
        }
    },

    renameCollectionPost: (form) => {
        const data = $(form).serializeForm(),
            collectionID = $('#js-collection-id').val();
        data.action = 'rename';
        data.collection_ids = [collectionID];
        $.ajax({
            url: '/collections/',
            type: 'POST',
            data: JSON.stringify(data),
            success: function (response) {
                UserCollections.renameCollectionPostSuccess(response, form);
            },
            error: function () {
                nytNotification('error', globalMessages().genericRequestError);
            }
        });
    },

    renameCollectionPostSuccess: (response, form) => {
        if(response.success) {
            const modalInit = Modal.getInstance(document.getElementById('id_modal_confirmation_rename') as HTMLElement);
            nytNotification('success', globalMessages().collectionRenameSuccess);
            $('#js-collection-name-' + response.data.collection_ids).text(response.data.value);
            $('#js-collection-navlink-' + response.data.collection_ids +' a').text(response.data.value);
            modalInit && modalInit.hide();
            $('#js-update-collection-name').val('');
            $('#js-collection-' + response.data.collection_ids + ' .rename').attr('data-title', response.data.value);
            $('#js-collection-' + response.data.collection_ids + ' .delete').attr('data-title', response.data.value);
        } else {
            $(form).find('.error.value')
                .html(response.error)
                .closest('.form-group').addClass('has-error');
        }
    },

    initDeleteModal: (event) => {
        event.preventDefault();
        const element = event.target,
        collectionTitle = (($(element).attr('data-title')) + '').replace(/<\/?[^>]+(>|$)/g, ""),
        collectionId = $(element).attr('data-id'),
        slectedCollections = UserCollections.getSelectedCollections();
        let message;
        if (collectionTitle === 'multiple-delete-btn') {
            if (slectedCollections.length) {
                message = '<strong>selected</strong> collections';
                UserCollections.deteleConfirmation(message, collectionId);
            } else {
                nytNotification('error', globalMessages().collectionItemDeleteNoSelected);
            }
        } else {
            message = 'the collections <strong>' + collectionTitle + '</strong>';
            UserCollections.deteleConfirmation(message, collectionId);
        }
    },

    deteleConfirmation: (message, collectionId) => {
        nytConfirm("You're about to delete " + message + " from your profile. Please confirm.", {
            yesText: 'Delete',
            noText: 'Cancel',
            onConfirm: function () {
                UserCollections.deleteCollection(collectionId);
            }
        });
    },

    deleteCollection: (collectionId) => {
        const data = {
            action: 'delete',
        };
        if (collectionId === 'multiple-delete-btn') {
            data['collection_ids'] = UserCollections.getSelectedCollections();
        } else {
            data['collection_ids'] = [collectionId];
        }

        $.ajax({
            url: '/collections/',
            type: 'POST',
            data: JSON.stringify(data),
            success: function (response) {
                UserCollections.deleteCollectionSuccess(response);
            },
            error: function () {
                nytNotification('error', globalMessages().genericRequestError);
            }
        });
    },

    getSelectedCollections: () => {
        const collectionRows = $('.sec__main--collections .table tbody .checked'),
        collections: string[] = [];
        $.each(collectionRows, function (i, row) {
            collections.push($(row).attr('data-id') || '');
        });
        return collections;
    },

    deleteCollectionSuccess: (response) => {
        const collectionIds= response.data.collection_ids;
        if (String(response.success) === 'true') {
            for (let i = 0; i < collectionIds.length; i++) {
                $('#js-collection-' + collectionIds[i]).remove();
                $('#js-collection-navlink-' + collectionIds[i]).remove();
            }

            nytNotification('success', globalMessages().collectionDeleteSuccess);
            $('.js-count-collections').text(response.collections_count);
        }
    },

    shareCollection: (e) => {
        e.preventDefault();
        $.ajax({
            url: '/collections/',
            type: 'POST',
            data: JSON.stringify({
                'collection_ids': [$(e.currentTarget).attr('data-id')],
                'action': 'share'
            }),
            success: function (response) {
                if (String(response.success) === 'true') {
                    $('#js-share-link').val(response.previewURL);
                    const modalInit = new Modal(document.getElementById('share-collection') as HTMLElement);
                    modalInit && modalInit.show();
                }
            },
            error: function () {
                nytNotification('error', globalMessages().genericRequestError);
            }
        });
    },

    copyShareLink: (e) => {
        e.preventDefault();
        copy2clipboard('js-share-link');
    }
};

export default UserCollections;
