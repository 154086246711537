import BaseView from './BaseView';
import CsrfTokenSetup from '../services/CsrfTokenSetup';
import UserManage from '../services/UserManage';

function UserManageView (this: any, options) {
    this.$el = BaseView.initView(options);

    this.init = function () {
        CsrfTokenSetup.init();
        UserManage.init();
    };

    this.init();
}
export default UserManageView;
