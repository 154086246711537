import BaseView from './BaseView';
import CsrfTokenSetup from '../services/CsrfTokenSetup';
import RegisterForm from '../services/RegisterForm';
import ResendConfirmationForm from '../services/ResendConfirmationForm';

function RegisterView (this: any, options) {
    this.$el = BaseView.initView(options);

    this.init = function () {
        CsrfTokenSetup.init();
        RegisterForm.init();
        ResendConfirmationForm.init();
    };

    this.init();
}
export default RegisterView;
