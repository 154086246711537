import $ from 'jquery';
import globalMessages from '../globalMessages';
import NotificationsSocket from './NotificationsSocket';

var ManageNotifications = {
    init: () => {
        document.querySelectorAll('.actions-panel-action .toggle-input')?.forEach(input => input.addEventListener('change', ManageNotifications.toggleChange));
        document.querySelector('.sec__main--profile-content .js-more-notifications')?.addEventListener('click', ManageNotifications.loadMoreNotifications);
        
        NotificationsSocket.sendMessage({'type': 'read_notifications'});
    },

    toggleChange: (e) => {
        const checkbox = e.currentTarget,
        checkboxName = checkbox.getAttribute('name');
        NotificationsSocket.sendMessage({
            'type': 'manage_notifications',
            'field': checkboxName,
            'value': checkbox.checked
        });
    },

    loadMoreNotifications: (e) => {
        const moreBtn = e.currentTarget;
        NotificationsSocket.sendMessage({
            'type': 'load_notifications',
            'page': moreBtn.getAttribute('data-page')
        });
    }
};

export default ManageNotifications;
