import $ from 'jquery';
import 'jquery-datetimepicker';
import { TomInput } from 'tom-select/dist/types/types';

var ActivityForm = {
    init: function () {
        (<any>$('#id_range_from')).datetimepicker({
            format:'m/d/Y H:i',
            onShow: function () {
                this.setOptions({
                    maxDate: $('#id_range_to').val() ? $('#id_range_to').val() : false
                });
            }
        });

        (<any>$('#id_range_to')).datetimepicker({
            format:'m/d/Y H:i',
            onShow: function () {
                this.setOptions({
                    minDate: $('#id_range_from').val() ? $('#id_range_from').val() : false
                });
            }
        });
        $('.reset-filter a').on('click', ActivityForm.resetFilter);
    },

    resetFilter: (e) => {
        e.preventDefault();
        const inputs = $(e.target).closest('form').find('input.form-control');
        // reset all input fields
        $.each(inputs, (i, el) => {
            $(el).val('');
        });
        // reset select option if exist
        const actionSelect = document.getElementById('id_action') as TomInput;
        if(actionSelect) {
            actionSelect.tomselect?.clear();
        }
        // submit form
        $('.filter-buttons .filter').trigger('click');
    }
};

export default ActivityForm;
