
import $ from 'jquery';
import {getCookie, setCookie} from '../globalFunctions';
import BaseView from './BaseView';
import CsrfTokenSetup from '../services/CsrfTokenSetup';
import SearchForm from '../services/SearchForm';
import ContentInquiryForm from '../services/ContentInquiryForm';
import SavedSearch from '../services/SavedSearch';
import '../balancedGallery';

function SearchView(this: any, options) {
    this.$el = BaseView.initView(options);

    this.DatePickerInit = () => {
        const _this = this;
        (<any>$('#filter_date_from')).datetimepicker({
            format:'m/d/Y',
            timepicker: false,
            yearStart: 2009,
            yearEnd: (new Date()).getFullYear() + 1,
            onShow: function () {
                this.setOptions({
                    maxDate: $('#filter_date_to').val() ? _this.formatDate($('#filter_date_to').val(), "yyyy/MM/dd") : false
                });
            },
            onClose: function () {
                if($('#filter_date_from').val() != '' || $('#filter_date_to').val() != '') {          
                    $('.js-time .selected-options').text($('#filter_date_from').val() +' - ' + $('#filter_date_to').val());
                    $('.js-time .dropdown-toggle').addClass('has-selections');
                    $('.js-time input[type="radio"]:checked').prop('checked', false);
                    $('.js-time .checked').removeClass('checked');
                    $('.js-time .selected-options-cta').removeClass('d-none');
                } else {
                    $('.js-time input.default').trigger('click');
                }
                SearchForm.countFilters();
            }
        });

        (<any>$('#filter_date_to')).datetimepicker({
            format:'m/d/Y',
            timepicker: false,
            yearStart: 2009,
            yearEnd: (new Date()).getFullYear() + 1,
            onShow: function () {
                this.setOptions({
                    minDate: $('#filter_date_from').val() ? _this.formatDate($('#filter_date_from').val(), "yyyy/MM/dd") : false
                });
            },
            onClose: function () {
                if($('#filter_date_from').val() != '' || $('#filter_date_to').val() != '') {
                    $('.js-time .selected-options').text($('#filter_date_from').val() +' - ' + $('#filter_date_to').val());
                    $('.js-time .dropdown-toggle').addClass('has-selections');
                    $('.js-time input[type="radio"]:checked').prop('checked', false);
                    $('.js-time .checked').removeClass('checked');
                } else {
                    $('.js-time input.default').trigger('click');
                }
            }
        });
    };

    this.formatDate = (time, format) => {
        const t = new Date(time);
        const tf = function(i) {
            return (i < 10 ? '0' : '') + i
        };
        return format.replace(/yyyy|MM|dd|HH|mm|ss/g, function(a) {
            switch (a) {
                case 'yyyy':
                    return tf(t.getFullYear());
                case 'MM':
                    return tf(t.getMonth() + 1);
                case 'mm':
                    return tf(t.getMinutes());
                case 'dd':
                    return tf(t.getDate());
                case 'HH':
                    return tf(t.getHours());
                case 'ss':
                    return tf(t.getSeconds());
            }
        })
    };

    this.waitForImages = () => {
        const imgs = $('.articles.gallery .js-thumbnail'),
            len = imgs.length;
        let counter = 0;
            
        [].forEach.call(imgs, function(img) {
            // fix for browser cache
            $(img).one('load', function() {
                incrementCounter();
            }).one('error', function() {
                $(img).closest('.item.gallery-view').remove();
                incrementCounter();
            }).each(function() {
                if((<HTMLImageElement>this).complete) {
                    // fix when image fail and user load images from cache
                    if ((<HTMLImageElement>this).naturalWidth === 0) {
                        $(this).closest('.item.gallery-view').remove();
                        incrementCounter();
                    }
                    $(this).trigger('load');
                }
            });
        });

        function incrementCounter() {
            counter++;
            if ( counter === len ) {              
                pageLoaded();
                const $galleryView = $(".articles:not(.one-result)");
                if($galleryView.length) {
                    (<any>$galleryView).BalancedGallery({
                        idealHeight: 342,
                        padding: 24
                    });
                }
            }
        }

        function pageLoaded() {
            // all images should be loaded here               
            $('.search-results .articles').removeClass('loading');
            // fix for article title text clamp
            $('.search-results .articles .item .article-title, .search-results .articles .item .extra-info-ed, .articles .item .clamp-one, .articles .item .search-list-view .content-container-box h2').addClass('full-width');
            $('.search-results .search-results-loading-icon').remove();
        }
    };

    this.filterCollapse = function () {
        if (window.innerWidth <= 768) {
            // display only grid view for mobile 
            const viewMode = getCookie('view-mode');
            if (viewMode && viewMode != 'grid') {
                setCookie('view-mode', 'grid', 365);
                window.location.reload();
            }
        }
    };

    this.init = function () {
        if($('.articles.gallery').length) {
            this.waitForImages();
        }
        this.filterCollapse();
        CsrfTokenSetup.init();
        SearchForm.init();
        ContentInquiryForm.init();
        SavedSearch.init();
        this.DatePickerInit();
    };

    this.init();
}

export default SearchView;
