/* eslint-disable indent */
import $ from 'jquery';
import { Modal, Popover } from 'bootstrap';
import {fetchCall, formSerialize, nytConfirm, nytNotification} from '../globalFunctions';
import BaseView from './BaseView';
import ContentInquiryForm from '../services/ContentInquiryForm';
import CollectionModal from '../services/CollectionModal';
import AssociateInvitationForm from '../services/AssociateInvitationForm';
import ReportUseForm from '../services/ReportUseForm';
import ContentShareForm from '../services/ContentShareForm';
import RelatedContent from '../services/RelatedContent';
import SlickCarousel from '../services/SlickCarousel';
import CollectionPopover from '../services/CollectionPopover';
import ContentActivityModal from '../services/ContentActivityModal';
import TomSelect from 'tom-select';
import SearchForm from '../services/SearchForm';
import globalMessages from "../globalMessages";

function ContentView(this: any, options) {
    this.$el = BaseView.initView(options);
    SlickCarousel.gallery();
    RelatedContent.init();
    const _this = this;

    this.externalLink = function (e) {
        e.preventDefault();
        const url = $(e.target).attr('href');


        nytConfirm("<strong>You are leaving NYTLicensing.com</strong> <br /><br />By clicking “continue,” you will open the URL in a new window/tab.", {
            yesText: 'Continue',
            noText: 'Cancel',
            onConfirm: function () {
                window.open(url, '_blank');
                syn4('outbound', {'page_url': window.location.href, 'name': 'Redirect URL or interstital', 'click_text': (document.querySelector('.sec__main--content__wrapper-title h1') as HTMLElement).innerText, 'destination': url});
            }
        });
    };

    this.customRightClick = function () {
        // disable cut copy paste
        $('article.js-cndw').bind('cut copy paste', function (e) {
            if (!$(e.target).hasClass('caption')) {
                e.preventDefault();
            }
        });

        $('body article.js-cndw').on("contextmenu", function (e) {
            if (!$(e.target).hasClass('caption')) {
                return false;
            }
        });

        $('body article.js-cdw').on("contextmenu", function (e) {
            _this.customRightClickContextmenu(e);
        });

        // prevent modal close when click on image
        $('.modal.image-full-screen img').on('click', (e) => {
            e.stopPropagation();
        });
    };

    this.customRightClickContextmenu = function (e) {
        function startFocusOut() {
            $(document).on("click", function () {
                $('.js-copy-to-clipboard-container').hide();
            });
            return true;
        }

        if (!$(e.target).hasClass('caption')) {
            e.preventDefault();
            $('.js-copy-to-clipboard-container').css({
                top: e.clientY - 56 + "px", // height + 8px the arrow
                left: e.clientX - 118 + "px" // half of width 236px
            }).fadeIn(0, startFocusOut);
        }
    };

    this.copyToClipBoard = function (event) {
        event.preventDefault();
        let range;
        const getSelection = window.getSelection();
        const text_copied_to_clipboard = document.getElementById('js-text-copied-to-clipboard');
        if (getSelection) {
            range = document.createRange();
            range.selectNode(text_copied_to_clipboard);
            getSelection.removeAllRanges();
            getSelection.addRange(range);
        }
        document.execCommand("copy");
        fetchCall(window.location.href, 'post', { "action": "copy" },
            function (response) {}
        );
    };

    this.init = function () {
        this.customRightClick();
        this.displayAddToCartModal();
        ContentInquiryForm.init();
        CollectionModal.init();
        AssociateInvitationForm.init();
        ReportUseForm.init();
        ContentShareForm.init();
        ContentActivityModal.init();
        $('#js-copy-to-clipboard').on('click', this.copyToClipBoard);
        $('.article-content-details p a:not(.not-external)').on('click', this.externalLink);
        document.querySelector('#content-add-to-cart form')?.addEventListener('submit', SearchForm.addToCart);
        document.querySelectorAll('.js-add-to-cart-missing')?.forEach(el => el?.addEventListener('click', SearchForm.addToCartMissingInfo));


        const modalGallery =  $('.js-gallery-slider.slick-initialized');
        if (modalGallery.length) {
            $('.related-gallery-item-image').on('click', function () {
                const slideNumber = parseInt($(this).attr('slide-order') || '0');
                $('.gallery-slider').slick("goTo", slideNumber - 1);
                modalGallery.addClass('js-details');
                //initiate popover on 'add to collection' btn in modal
                const popoverTriggerList = [].slice.call(document.querySelectorAll('.btn-collection-popover'))
                const popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
                    let pop =  new Popover(popoverTriggerEl, {
                        html: true,
                        placement: 'top',
                        container: (document.getElementById('content-gallery-modal') as HTMLElement),
                        sanitize: false,
                        content: function() {
                            return $(this).siblings('.popover-btn-content').html()
                        }
                    });

                    (popoverTriggerEl as HTMLElement).addEventListener('shown.bs.popover', function (e) {
                        CollectionPopover.init();
                        // init tomselect in popover select
                        const popoverSelect = document.querySelector('.popover .select select') as HTMLSelectElement,
                        popoverSelectInst = new TomSelect(popoverSelect, {plugins: ['dropdown_input']});
                        (<HTMLElement>document.getElementsByClassName('popover')[0]).style.top = parseInt((<HTMLElement>document.getElementsByClassName('popover')[0]).style.top, 10) - 46 + 'px'
                        let dataSyn = (e.target as HTMLElement).getAttribute('data-syn') || '';
                    });
                });
            });
            $(window).on('load', function () {
                _this.viewMoreBtn();
            });
        }

        // execute only if content page is for media (graphic, photo)
        const imageContainer = $('.article-content-details .article-photo'),
        fullScreenBtn = imageContainer.find('.article-photo-full'),
        image = imageContainer.find('.article-photo-thumnail');
        if (imageContainer.length) {
            // do this only after image is loaded
            image.on('load', function (e) {
                $(e.target).removeClass('d-none');
                fullScreenBtn.removeClass('d-none');
                imageContainer.find('.loading-icon').addClass('d-none');
                // attach same source/image to full screen modal
                $('#image-full-screen .js-image-full-screen').attr('src', image.data('src'))
            });
            // move source from attr to img src
            image.attr('src', image.data('src'));
        }
    };

    this.viewMoreBtn = () => {
        const items = $('.content-gallery-modal .item-details-description');
        $.each(items, (i, item) => {
            let contentHeight = $(item).find('span').height() || 0;
            if(((window.innerWidth > 1024) && (contentHeight >= 96)) || ((window.innerWidth <= 1024) && (contentHeight >= 83))) {
                $(item).next('.view-label').removeClass('d-none');
            }
        });
    }

    this.displayAddToCartModal = () => {
        const urlSearchParams = new URLSearchParams(window.location.search),
        actionParameter = urlSearchParams.get('action'),
        addToCartEl = document.getElementById('content-add-to-cart');

        if(actionParameter == 'add-to-cart' && addToCartEl) {
            window.history.replaceState({}, '', window.location.pathname);
            new Modal(addToCartEl)?.show();
        }
    }

    this.init();
}

export default ContentView;
