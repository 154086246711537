import $ from 'jquery';
import BaseView from './BaseView';
import CsrfTokenSetup from '../services/CsrfTokenSetup';
import InfringementForm from '../services/InfringementForm';

function InfringementView (this: any, options) {
    this.$el = BaseView.initView(options);

    this.init = function () {
        CsrfTokenSetup.init();
        InfringementForm.init();
    };

    this.init();
}
export default InfringementView;