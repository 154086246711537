///<reference types="slick-carousel"/>
import $ from 'jquery';
import globalMessages from '../globalMessages';
import CsrfTokenSetup from '../services/CsrfTokenSetup';
import ContactForm from '../services/ContactForm';
import LoginForm from '../services/LoginForm';
import AssociateInvitationForm from '../services/AssociateInvitationForm';
import SlickCarousel from '../services/SlickCarousel';
import MarketerFeedContent from '../services/MarketerFeedContent';
import ContentInquiryForm from '../services/ContentInquiryForm';
import FeedbackForm from '../services/SiteFeedbackForm';
import PurrIntegration from '../services/PurrIntegration';

CsrfTokenSetup.init();
ContactForm.init();
LoginForm.init();
AssociateInvitationForm.init();
SlickCarousel.init('.recent-content');
SlickCarousel.init('.service-gallery');
SlickCarousel.init('.slider');
MarketerFeedContent.init();
ContentInquiryForm.init();
FeedbackForm.init();
PurrIntegration.init();

$('.service-gallery .carousel-image--link').on('click', function () {
    SlickCarousel.init('.service-gallery-modal');

    const modalGallery = $('.service-gallery-modal');
    const loadingIcon =  $('.sec__main--gallery-service-modal .loading-icon .js-loading');
    const slideNumber = $(this).attr('slide-order');

    loadingIcon.css('display', 'block');

    modalGallery.css('visibility', 'hidden').slick("goTo", slideNumber);

    setTimeout(function(){
        loadingIcon.css('display', 'none');
        modalGallery.find('.item-details-title').addClass('js-truncate');
        modalGallery.css('visibility', 'visible');
        $('.service-gallery-modal').slick('resize');

    }, 1500);
});

// pause player if service gallery modal is hiding
$('.service-gallery-modal, #preview-content-modal').on('hide.bs.modal', function () {
    try {
        let jwState = window.jwplayer("media-container").getProvider() && window.jwplayer("media-container").getProvider().name == 'html5' || window.jwplayer("media-container").getProvider() && window.jwplayer("media-container").getProvider().name == 'flash';
        
        if (jwState && (window.jwplayer().getState() === 'playing')) {
            window.jwplayer().pause();            
        }
    }
    catch(err) {
        console.error(globalMessages().generalError);
    }
});

const BaseView = {
    'initView': function (options) {
        return options && options.$el ? options.$el : $('body')
    }
};

export default BaseView;
