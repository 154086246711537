import $ from 'jquery';
import {Modal} from 'bootstrap';
import globalMessages from '../globalMessages';
import {nytNotification, checkForm} from '../globalFunctions';

// $ is the export of the jquery module.
const ContentInquiryForm = {
    init: function () {
        $('#contactSalesRepForm').off().on('submit', ContentInquiryForm.post);
        $('[data-bs-target="#contactSalesRepModal"]').off().on('click', ContentInquiryForm.modalTitle);
    },

    // populate model title
    modalTitle: function (e) {
        const target = e.currentTarget;
        // remove line breaks from value
        const title = target.getAttribute('data-title') + '',
        titleFinal = title ? 'Inquiry about: "' + title + '"' :
            'Contact your sales representative about this content.',
        link = target.getAttribute('data-link') ? target.getAttribute('data-link') : window.location.pathname,
        titleElement = document.getElementById("contactSalesRepModal")!.getElementsByClassName("modal-title")[0] as HTMLElement;
        titleElement.innerText = titleFinal;
        $('#contactSalesRepForm [name="title"]').val(title);
        $('#contactSalesRepForm [name="link"]').val(link + '');
    },

    post: function (event) {
        event.preventDefault();
        const form: HTMLFormElement = event.currentTarget,
        formBtn = document.getElementById('contactSalesRepForm')!.getElementsByClassName('btn')[0];
        formBtn.setAttribute('disabled', 'disabled');
        // do action only if form is valid
        if (checkForm(form)) {
            ContentInquiryForm.contactRep(form);
        } else {
            formBtn.removeAttribute('disabled');
        }
    },

    contactRep: function (form) {
        const dataForm = $(form).serializeForm();
        $(form).find('.btn').removeAttr('disabled');
        $.ajax({
            url: '/contact-rep/', // the endpoint
            type: 'POST', // http method
            data: dataForm,
            // handle a successful response
            success: function (response) {
                ContentInquiryForm.contactRepSuccess(response, form);
            },
            // handle a non-successful response
            error: function () {
                nytNotification('error', globalMessages().genericRequestError);
                $(form).find('.btn').removeAttr('disabled');
            }
        });
    },

    contactRepSuccess: function (response, form) {
        $(form).find('.error').empty().closest('.form-group').removeClass('has-error');
        if (String(response.success) === 'true') {
            const fromServicePage = form.getAttribute('data-service');
            if (fromServicePage == '1') {
                syn4('form_submit', {'conversion': 'No', 'page_url': form.title, 'name': 'Inquiry Form'});
            }
            if (response.reason) {
                nytNotification('error', response.reason);
            } else {
                $(form)[0].reset();
                const modalInit = Modal.getInstance(document.getElementById('contactSalesRepModal') as HTMLElement);
                modalInit && modalInit.hide();
                nytNotification('success', globalMessages().successContactForm);
            }
        } else {
            $.each(response.errors, function (id: string, error) {
                if (id === '__all__') {  // case for generic invalid message ? empty message
                    nytNotification('error', response.reason);
                } else {
                    $(form).find('.error.' + id).html(error).closest('.form-group').addClass('has-error');
                }
            });
        }
    }
};

export default ContentInquiryForm;
