import $ from 'jquery';
import { Modal } from 'bootstrap';
import globalMessages from '../globalMessages';
import {nytConfirm, nytNotification, checkForm} from '../globalFunctions';

// $ is the export of the jquery module.
var ProfileForm = {
    init: () => {
        $('#id-profile-settings-form').on('submit', ProfileForm.post);
        $('#js-password-reset-confirm').on('click', ProfileForm.resetPassword);
        $('.js-notification-action' ).on('click', ProfileForm.pardotConfirm);
        $('.js-magazine-pdf, .js-t-magazine-pdf').on('click', ProfileForm.magazinePDF);
    },

    post: (e) => {
        e.preventDefault();
        // do action only if form is valid
        if (checkForm(e.target)) {
            ProfileForm.saveProfile(e.target);
        }
    },

    resetPassword: function (event) {
        event.preventDefault();
        $.ajax({
            url: '/profile/?reset-password=1', // the endpoint
            type: 'GET', // http method
            success: function () {
                const modalInit = Modal.getInstance(document.getElementById('id_modal_confirmation_reset_password') as HTMLElement);
                modalInit && modalInit.hide();
                nytNotification('success', globalMessages().successPasswordReset);
            },
            error: function () {
                nytNotification('error', globalMessages().genericRequestError);
            }
        });
    },

    saveProfile: (form) => {
        var dataForm = $(form).serializeForm();
        $(form).find('.error').empty().closest('.form-group').removeClass('.has-error');
        $.ajax({
            url: '/profile/', // the endpoint
            type: 'POST', // http method
            data: dataForm,
            // handle a successful response
            success: (response) => {
                ProfileForm.saveProfileSuccess(response, form);
            },
            // handle a non-successful response
            error: () => {
                nytNotification('error', globalMessages().genericRequestError);
            }
        });
    },

    saveProfileSuccess: (response, form) => {
        $(form).find('.error').empty().closest('.form-group').removeClass('has-error');
        if (String(response.success) === 'true') {
            if (response.redirect_url) {  // case staff user: redirect to admin interface
                window.location.href = window.location.protocol + '//' + response.redirect_url;
            }
            let fname: string = $('#profile--first_name').val() + '',
            lname: string = $('#profile--last_name').val() + '';
            $('.sec__main--profile-name .first-name').text(fname);
            $('.sec__main--profile-name .last-name').text(lname);
            $('.sec__main--navbar-item-logged-cta-user.dropdown-toggle .fname').text(fname);
            nytNotification('success', globalMessages().profileUpdated);
        } else {
            nytNotification('error', globalMessages().generalError);
            $.each(response.errors, function (id: string, error: string) {
                if (id === '__all__') {  // case for invalid login - generic message.
                    nytNotification('error', error);
                }
                $(form).find('.error.' + id)
                    .html(error)
                    .closest('.form-group').addClass('has-error');
            });
        }
    },

    pardotConfirm: (e) => {
        e.preventDefault();
        const action = e.currentTarget.checked ? 'subscribe' : 'unsubscribe',
        confirmationText = {
            subscribe: 'You would like to receive emails containing news content, updates and special offers from New York Times Licensing. You may opt out at anytime.',
            unsubscribe: 'You are about to unsubscribe from New York Times Licensing\'s email newsletters.'
        };

        nytConfirm(confirmationText[action], {
            yesText: 'Confirm',
            noText: 'Cancel',
            onConfirm: function () {
                e.currentTarget.checked = !e.currentTarget.checked;
                ProfileForm.pardotPost(action);
            }
        });
    },

    pardotPost: (action) => {
        $.ajax({
            url: '/profile/?' + action,
            type: 'POST',
            success: function (response) {
                if (response.success) {
                    nytNotification('success', globalMessages().preferencesSaved);
                }
            },
            error: function () {
                nytNotification('error', globalMessages().genericRequestError);
            }
        });
    },

    magazinePDF: (e) => {
        e.preventDefault();
        const target = $(e.currentTarget),
        action = e.currentTarget.checked ? 'subscribe' : 'unsubscribe',
        confirmationText = {
            'nyt magazine': {
                subscribe: 'Click Subscribe to confirm that you would like to receive weekly emails with advanced PDF copies of The New York Times Magazine. You may opt out or contact us at any time.',
                unsubscribe: 'Click Unsubscribe to confirm that you would no longer like to receive weekly emails with advanced PDF copies of The New York Times Magazine.'
            },
            't magazine': {
                subscribe: 'Click Subscribe to confirm that you would like to receive emails with advanced PDF copies of T Magazine 11 times per year. You may opt out or contact us at any time.',
                unsubscribe: 'Click Unsubscribe to confirm that you would no longer like to receive emails with advanced PDF copies of T Magazine 11 times per year.'
            }
        };

        let sub;
        if (target.hasClass('js-magazine-pdf')) {
            sub = 'nyt magazine';
        } else if (target.hasClass('js-t-magazine-pdf')) {
            sub = 't magazine';
        }

        nytConfirm(confirmationText[sub][action], {
            yesText: 'Confirm',
            noText: 'Cancel',
            onConfirm: function () {
                e.currentTarget.checked = !e.currentTarget.checked;
                ProfileForm.magazinePDFPost(action, sub);
            }
        });
    },

    magazinePDFPost: (action, sub) => {
        const dataForm = {
            action: action,
            subscription: sub
        };

        $.ajax({
            url: '/newsletter-subscription/',
            type: 'POST',
            data: dataForm,
            success: function (response) {
                if (response.success) {
                    nytNotification('success', globalMessages().preferencesSaved);
                }
            },
            error: function () {
                nytNotification('error', globalMessages().genericRequestError);
            }
        });
    }
};

export default ProfileForm;
