import $ from 'jquery';
import globalMessages from '../globalMessages';
import UserManage from './UserManage';
import {checkForm, nytNotification} from '../globalFunctions';
import { Modal } from 'bootstrap';

var AssociateInvitationForm = {

    init: function () {
        $('.invite-form').on('submit', AssociateInvitationForm.post);
    },
    
    post: function (e) {
        e.preventDefault();
        const target = e.target;
        // do action only if form is valid
        if (checkForm(target)) {
            AssociateInvitationForm.invite(target);
        }
    },

    invite: function (form) {
        var dataForm = $(form).serializeForm();

        $.ajax({
            url: '/invite/',
            type: 'POST',
            data: dataForm,
            success: function (response) {
                AssociateInvitationForm.inviteSuccess(response, form);
            },
            error: function () {
                nytNotification('error', globalMessages().genericRequestError);
            }

        });
    },

    inviteSuccess: (response, form) => {
        $(form).find('.error').empty().closest('.form-group').removeClass('has-error');

        if (String(response.success) === 'true') {
            if (response.redirect_url) {  // case staff user: redirect to admin interface
                window.location.href = window.location.protocol + '//' + response.redirect_url;
            } else {
                let modalSelector = 'id_modal_invite';
                if (response.user.groups) {
                    modalSelector = 'billing_role_invitation';
                }
                // select propper modal and hide
                const modalInit = Modal.getInstance(document.getElementById(modalSelector) as HTMLElement);
                modalInit && modalInit.hide();
                // reset form
                $(form)[0].reset();
                // remove row with no user available after add first associate
                const noUser = $('#usermgmt .table tbody .no-results');
                if (noUser.length ) {
                    noUser.remove();
                }

                $('#usermgmt .table tbody').prepend(
                    `<tr data-id="${response.user.id}">
                    <td><div class="checkbox"><label><input type="checkbox"><span class="check-container"><span class="icon glyphicon glyphicon-ok"></span></span></label></div></td>
                    <td class="vmiddle fname">${response.user.first_name}</td>
                    <td class="vmiddle lname">${response.user.last_name}</td>
                    <td class="vmiddle"><a href="mailto:${response.user.email}" class="email">${response.user.email}</a></td>
                    <td class="vmiddle">${response.user.last_login ?? 'User has never logged in'}</td>
                    <td class="vmiddle">
                    <div class="dropdown-link">
                    <span class="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="true" data-bs-display="static"><span class="drop-val" data-field="groups">${response.user.groups ?? 'Associate'}</span> <span class="arrow-down"><svg class="svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.41 10.12"><polygon points="8.71 10.12 0 1.41 1.41 0 8.71 7.29 16 0 17.41 1.41 8.71 10.12"/></svg></span></span>
                    <div class="dropdown-menu">
                    <div class="dropdown-container">
                    <div><a href="#" data-field="groups" data-value="Account holder">Administrator</a></div>
                    <div><a href="#" data-field="groups" data-value="Associate">Associate</a></div>
                    <div><a href="#" data-field="groups" data-value="Billing">Finance</a></div>
                    </div>
                    </div>
                    </div>
                    </td>
                    <td class="vmiddle">
                    ${response.user.groups ? 'Download & Purchase' : `<div class="dropdown-link">
                    <span class="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="true" data-bs-display="static"><span class="drop-val" data-field="permissions">${response.user.buy_options}</span> <span class="arrow-down"><svg class="svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.41 10.12"><polygon points="8.71 10.12 0 1.41 1.41 0 8.71 7.29 16 0 17.41 1.41 8.71 10.12"/></svg></span></span>
                    <div class="dropdown-menu">
                    <div class="dropdown-container">
                    <div><a href="#" data-field="permissions" data-value="Download & Purchase">Download & Purchase</a></div>
                    <div><a href="#" data-field="permissions" data-value="Download Only">Download Only</a></div>
                    </div>
                    </div>
                    </div>`}
                    </td>
                    <td class="vmiddle">
                    <div class="dropdown-link">
                     <span class="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="true" data-bs-display="static"><span class="drop-val" data-field="is_active">Inactive</span> <span class="arrow-down"><svg class="svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.41 10.12"><polygon points="8.71 10.12 0 1.41 1.41 0 8.71 7.29 16 0 17.41 1.41 8.71 10.12"/></svg></span></span>
                    <div class="dropdown-menu">
                     <div class="dropdown-container">
                    <div><a href="#" data-field="is_active" data-value="true">Active</a></div>
                    <div><a href="#" data-field="is_active" data-value="false">Inactive</a></div>
                    <div><a href="#" data-field="is_archived" data-value="true">Remove</a></div>
                    </div>
                     </div>
                     </div>
                     </td>
                    </tr>`
                );

                nytNotification('success', globalMessages().associateAddSuccess);
                // unbind and re-bind events on select all and dropdown-menu for new added rows
                $("#usermgmt tbody :checkbox").off().on("change", UserManage.checkItem);
                $('#usermgmt .dropdown-menu a').off().on('click', UserManage.changeState);
            }
        } else {
            $.each(response.errors, function (id: string, error: string) {
                if (id === '__all__') {  // case for invalid invite - generic message.
                    nytNotification('error', error);
                }
                $(form).find('.error.' + id)
                    .html(error)
                    .parent().addClass('has-error');
            });
        }
    }
};

export default AssociateInvitationForm;
