import $ from 'jquery';
import globalMessages from '../globalMessages';
import CollectionModal from '../services/CollectionModal';
import {nytNotification, setCookie, numbersOnly, getSVGCode, copy2clipboard, checkForm, formSerialize, fetchCall, nytConfirm} from '../globalFunctions';
import { Modal } from 'bootstrap';
import { TomInput } from 'tom-select/dist/types/types';
import CartCheckout from './CartCheckout';


const SearchForm = {
    init: () => {
        document.querySelectorAll('.js-add-to-cart')?.forEach(el => el?.addEventListener('click', SearchForm.addToCartModal));

        $('.search-bar-input').on('focus', SearchForm.collapseFeeds);
        $('#searchForm').on('submit', SearchForm.submitSearch);
        $('.search-bar .icon-clear').on('click', SearchForm.clearField);
        $('.sec__main--feeds-section-navigator li > a').on('click', SearchForm.feedsSelection);
        $('.search-bar-dropdown-menu a').on('click', SearchForm.searchType);
        $('.search-meta-options .sort-link').on('click', SearchForm.sortSelection);
        $('.change-list').on('click', SearchForm.changeDisplayMode);
        $('.search-results .articles .js-preview-modal').on('click', SearchForm.previewModal);
        $('#feedsCollapse').on('show.bs.collapse', SearchForm.feedsSectionBgShow);
        $('#feedsCollapse').on('hide.bs.collapse', SearchForm.feedsSectionBgHide);
        CollectionModal.init();
        SearchForm.feedsNavigationDetect();
        SearchForm.tdNotificationModal();

        // filters
        $('.sec__main--search-form-filters .sec__main--search-form-filters-more').on('click', SearchForm.openFilters);
        $('.sec__main--search-form-filters .filters-item.filters-expand .filters-item-cta .filters-item-link.expand-link').on('click', SearchForm.activateItem);
        $('.sec__main--search-form-filters .filters-item.filters-expand .filters-expand-content .back-link').on('click', SearchForm.mobileBack);
        $('.sec__main--search-form-filters .mobile-overlay').on('click', SearchForm.hideMobileFilters);
        $('.sec__main--search-form-filters .filters-expand-content input[type="checkbox"]').on('change', SearchForm.checkboxSelect);
        $('.sec__main--search-form-filters .filters-expand-content input[type="radio"]').on('change', SearchForm.radioSelection);
        $('.sec__main--search-form-filters .filters-item-cta .selected-options-cta').on('click', SearchForm.deleteSelections);
        $('#range-min, #range-max').on('input', SearchForm.restrictRange);
        $('.js-filters-reset').on('click', SearchForm.resetSearch);
        $('#artWith, #subSub').on('click', SearchForm.countFilters);
        $(document).on('mouseup', SearchForm.closeFilters);
        numbersOnly('range-min');
        numbersOnly('range-max');

        document.querySelectorAll('.js-service-share-url')?.forEach(el => el.addEventListener('click', SearchForm.populateServiceShareLink));
        document.querySelector('#js-share-link-copy')?.addEventListener('click', SearchForm.copyShareLink);
        document.querySelector('#content-add-to-cart form')?.addEventListener('submit', SearchForm.addToCart);
    },

    closeFilters: (e) => {
        const calendarEv = $('.xdsoft_datetimepicker').has(e.target).length,
        // flag if click is inside filter element or inside calendar
        // target of click is descendent of $('.filters-item-cta') || $('.filters-expand-content') || $('.js-filter') || $('.xdsoft_datetimepicker')
        flag = $('.filters-item-cta').has(e.target).length || $('.filters-expand-content').has(e.target).length || $('.js-filter').has(e.target).length || calendarEv,
        $targetParent = $(e.target).closest('.js-filter'),
        activeFilter = $('.filters-items .js-filter.active');

        if (!flag) {
            $('.filters-items .active:not(.filters-item-link)').removeClass('active');
        } else if (!(activeFilter.is($targetParent) || !!calendarEv)) { // target of click is the activeFilter container
            activeFilter.removeClass('active');
        }
    },

    openFilters: (e) => {
        e.preventDefault();
        $(e.target).toggleClass('collapsed');
        $(e.target).siblings('.filters-container').toggleClass('open');
    },

    activateItem: (e) => {
        e.preventDefault();
        const $targetParent = $(e.target).closest('.level-1');

        if ($targetParent.length) {
            setTimeout(function() {
                $(e.target).closest('.filters-expand-content').find('.level-1 .filters-item-cta > .filters-item-link').addClass('no-padding');
            }, 450);
        }
        $targetParent.closest('.filters-expand-content').animate({ scrollTop: 0 }, 450);
        $(e.target).closest('.filters-expand').toggleClass('active');
    },

    mobileBack: (e) => {
        e.preventDefault();
        const $targetParent = $(e.target).closest('.level-1');

        if ($targetParent.length) {
            $targetParent.closest('.filters-expand-content').find('.level-1 .filters-item-cta > .filters-item-link').removeClass('no-padding');
        }
        $(e.target).closest('.filters-expand').removeClass('active');
    },

    hideMobileFilters: () => {
        $('.sec__main--search-form-filters .filters-container').removeClass('open');
    },

    checkboxSelect: (event) => {
        const $thisCheckbox = $(event.currentTarget),
        checkboxValue = $thisCheckbox.attr('value'),
        checkboxParent = $thisCheckbox.attr('data-parent'),
        defaultOptionInput = $thisCheckbox.closest('.js-filter').find('.filters-expand-item input.default'),
        inputsContainer = $thisCheckbox.closest('.js-filter').find('.filters-expand-item input:not(.default)'),
        inputsContainerChecked = $thisCheckbox.closest('.js-filter').find('.filters-expand-content .filters-expand-item input:not(.default):checked'),
        inputsContainerCheckedSub = $thisCheckbox.closest('.filters-expand-content').find('input:checked');

        // when check all/any option
        if(checkboxValue === 'any' || checkboxValue === 'all') {
            $.each(inputsContainer, (i, el) => {
                (el as HTMLInputElement).checked = false;
                (<HTMLInputElement>el).checked = false;

                if (el instanceof HTMLInputElement) { //type guard
                    el.checked = false;
                }
            });
            if(!$thisCheckbox.is(':checked')) {
                $thisCheckbox.prop('checked', true);
            }
            $thisCheckbox.closest('.filters-expand-content').find('.filters-item-link.active').removeClass('active');
        // when check other option than all/any
        } else if(inputsContainerChecked.length) {
            defaultOptionInput.prop('checked', false);

            // services submenus
            if (checkboxParent && inputsContainerCheckedSub.length) {
                $('.' + checkboxParent).addClass('active');
            } else if (checkboxParent && !inputsContainerCheckedSub.length) {
                $('.' + checkboxParent).removeClass('active');
            }

        } else {
            defaultOptionInput.prop('checked', true);
            if(checkboxParent) {
                $('.' + checkboxParent).removeClass('active');
            }
        }
        SearchForm.grabSelectedOptions($thisCheckbox);
        SearchForm.countFilters();
    },

    radioSelection: (event) => {
        const radioBtn = $(event.target),
        radioValue = radioBtn.val(),
        timeCheck = (radioBtn.attr('name') === 'time');

        if(radioValue === 'any' || radioValue === 'all') {
            //only for time filter - hide clear btn
            if(timeCheck) radioBtn.closest('.js-filter').find('.filters-item-cta .selected-options-cta').addClass('d-none');
        } else {
            //only for time filter - show clear btn
            if(timeCheck) radioBtn.closest('.js-filter').find('.filters-item-cta .selected-options-cta').removeClass('d-none');
        }
        SearchForm.grabSelectedOptions(radioBtn);
        //remove input values when select radio
        radioBtn.closest('.js-filter').find('input[type="text"]').val('');
        radioBtn.closest('.js-filter').find('.has-error').removeClass('.has-error');

        SearchForm.countFilters();
    },

    grabSelectedOptions: (element) => {
        const optionsContainer = element.closest('.js-filter').find('.filters-expand-content input:not(.default):checked'),
        dropdownBtn = element.closest('.js-filter').find('.filters-item-link.expand-link span.selected-options'),
        dropdown = element.closest('.js-filter').find('.filters-item-link.expand-link').eq(0),
        optionsList: string[] = [],
        timeCheck = ((element.attr('name')) === 'time');
        let optionsContainerTemp : string[];

        dropdownBtn.html('');

        //exception for time to show default value
        if(timeCheck) {
            optionsContainerTemp = element.closest('.js-filter').find('input:checked');
        } else {
            optionsContainerTemp = optionsContainer;
        }
        $.each(optionsContainerTemp, (i, el) => {
            optionsList.push($(el).data('text'));
        });

        if (optionsList.length) {
            dropdown.addClass('has-selections');
            dropdownBtn.html(optionsList.join(', '));
        } else {
            dropdownBtn.html('');
            dropdown.removeClass('has-selections');
        }
        SearchForm.countFilters();
    },

    deleteSelections: (e) => {
        const targetFilter = e.target.closest('.js-filter'),
        inputFields = targetFilter.querySelectorAll('input[type="text"]');

        targetFilter.querySelector('input.default').click();
        // this case apply for date picker fields
        if(inputFields.length) {
            inputFields.forEach(el => el.value = '');
        }
    },

    restrictRange: (e) => {
        // restrict range min and max
        const currentInput = $(e.target),
        minVal = parseInt(<string>$('#range-min').val()),
        maxVal = parseInt(<string>$('#range-max').val()),
        dependent = $(e.target).data('dependent'),
        rangeCheck = $('#range-check');

        if (!rangeCheck.is(':checked')) {
            rangeCheck.click();
        }
        $('#range-min, #range-max').closest('.input-group').removeClass('has-error');
        if ((maxVal < minVal) && $(dependent).val() !== '') {
            currentInput.closest('.input-group').addClass('has-error');
        }
        const minFilter = Number.isNaN(minVal)?'':minVal,
        maxFilter = Number.isNaN(maxVal)?'':maxVal;

        $('.js-word-count .selected-options').text(minFilter + ' - ' + maxFilter);
        $('.js-word-count .filters-item-cta .filters-item-link').addClass('has-selections');
        $('.js-word-count input.default').prop('checked', false);
    },

    // reset form to default values
    resetSearch: (e) => {
        e.preventDefault();
        const filters = $('.sec__main--search-form-filters .filters-container .filters-items');
        filters.find('input.default').trigger('click');
        filters.find('input.default input[type="text"]').val('');
        $('.search-bar-dropdown .search-bar-dropdown-menu li:first-child > a').trigger('click');
        $('#subSub').prop('checked', false);
        $('#artWith').prop('checked', false);
        // exception for language
        filters.find('input.exception').trigger('click');
    },

    submitSearch: (event) => {
        event.preventDefault();
        const data: any = $(event.target).serializeForm();
        data['action'] = 'get_search_ref';
        
        // search tracking
        syn4('search', {'page_url': window.location.href, 'search_action': data.categories, 'search_type': 'Category'});
        syn4('search', {'page_url': window.location.href, 'search_action': data.services, 'search_type': 'Service'});
        syn4('search', {'page_url': window.location.href, 'search_action': data.time, 'search_type': 'Time'});
        syn4('search', {'page_url': window.location.href, 'search_action': data.language, 'search_type': 'Language'});
        syn4('search', {'page_url': window.location.href, 'search_action': data.ctypes ? data.ctypes : 'any', 'search_type': 'Media Type'});
        syn4('search', {'page_url': window.location.href, 'search_action': data.ctypes ? data.ctypes : 'any', 'search_type': 'Media Type'});
        
        if (data.subscription) {
            syn4('search', {'page_url': window.location.href, 'search_action': data.subscription, 'search_type': 'Subscription'});
        }
        if (data.withart) {
            syn4('search', {'page_url': window.location.href, 'search_action': data.withart, 'search_type': 'With Art'});
        }

        if (data.format == 'person') {
            syn4('search', {'page_url': window.location.href, 'search_action': data.q, 'search_type': 'Journalist Search'});
        } else if (data.format == 'slug') {
            syn4('search', {'page_url': window.location.href, 'search_action': data.q, 'search_type': 'Slug Search'});
        } else {
            syn4('search', {'page_url': window.location.href, 'search_action': data.q, 'search_type': 'Keyword Search'});
        }
        
        // tracking for word count range
        if(data.wcountfrom !== '' || data.wcountto !== '') {
            syn4('search', {'page_url': window.location.href, 'search_action': `Range - ${data.wcountfrom} : ${data.wcountto}`, 'search_type': 'Word Count'})
        } else {
            syn4('search', {'page_url': window.location.href, 'search_action': 'Any', 'search_type': 'Word Count'})
        }

        fetchCall('/search/', 'post', data,
            function (response) {
                if(response.success) {
                    $(location).attr('href', '/search/' + response.search_ref);
                } else {
                    nytNotification('error', globalMessages().genericRequestError);
                }
            }
        );
    },

    cleanAddToCartModal: (modal, atributes) => {
        Object.keys(atributes).forEach(el => {
            const elem = modal!.querySelector(`.content-${el}`);
            if(el != 'fileTypes') {
                if(el == 'type' || el == 'id') {
                    (elem as HTMLInputElement).value = atributes[el];
                } else {
                    (elem as HTMLElement).textContent = atributes[el];
                }
            }
        });
        modal!.querySelector('form').reset();
        modal!.querySelectorAll('.js-file-type select option:not([disabled])').forEach(el => el.remove());
        modal!.querySelectorAll('.js-select select').forEach(el => el.tomselect.clear());
        modal!.querySelector('.js-file-type select').tomselect.clearOptions();
        modal!.querySelector('.js-format select').tomselect.setValue('2');
        modal!.querySelector('.js-file-type select').tomselect.sync();
    },

    addToCartModal: (e) => {
        const el = e.currentTarget,
        dataAtributes = el.dataset,
        checkDataAtributes = Object.values(dataAtributes).every(v => (v as string).length > 0)
        
        if (checkDataAtributes && Object.keys(dataAtributes).length) {
            // populate modal when open
            const addToCartModal = document.getElementById('content-add-to-cart') as HTMLElement,
            formatSelect = addToCartModal!.querySelector('.js-file-type select') as TomInput,
            formatedFileType = JSON.parse(`${dataAtributes['fileTypes'].replace(/'/g, '"')}`),
            addToCartModalInit = new Modal(addToCartModal);

            SearchForm.cleanAddToCartModal(addToCartModal, dataAtributes);
            Object.keys(dataAtributes).forEach(el => {
                const elem = addToCartModal!.querySelector(`.content-${el}`);            
                if(el != 'fileTypes') {
                    if(el == 'type' || el == 'id') {
                        (elem as HTMLInputElement).value = dataAtributes[el];
                    } else {
                        (elem as HTMLElement).textContent = dataAtributes[el];
                    }
                }
            });

            Object.keys(formatedFileType).forEach(el => {
                formatSelect?.insertAdjacentHTML('beforeend', `<option value="${el}">${formatedFileType[el]}</option>`);

            });
            formatSelect?.tomselect?.sync();
            addToCartModalInit?.show();
        } else {
            SearchForm.addToCartMissingInfo();
        }        
    },

    addToCartMissingInfo: () => {
        nytConfirm('This item can\'t be added to cart because it has missing information. Please contact support.', {
            noText: 'Close',
        });
    },

    addToCart: (e) => {
        e.preventDefault();
        const form = e.currentTarget,
        addButton = document.querySelector('.js-add-to-cart-btn'),
        reqObject = formSerialize(form);
        reqObject['action'] = 'add_item';

        if (checkForm(form)) {
            // prevent multiple click because of slow response
            addButton?.classList.add('loading');
            addButton?.setAttribute('disabled','');

            fetchCall('/cart/', 'post', reqObject,
                function (response) {
                    addButton?.classList.remove('loading');
                    addButton?.removeAttribute('disabled');
                    if (response.success) {
                        SearchForm.addToCartSuccess(response);
                    } else if (response.errors) {
                        Object.keys(response.errors).forEach(key => {
                            const input = form.querySelector(`.error.${key}`);
                            if (input) {
                                input.innerHTML = response.errors[key];
                                input.closest('.form-group')?.classList.add('has-error');
                            } else {
                                nytNotification('error', globalMessages().genericRequestError);
                            }
                        });
                    } else {
                        nytNotification('error', globalMessages().genericRequestError);
                    }
                }
            );
        }
    },

    addToCartSuccess: (response) => {
        document.querySelectorAll('.js-cart .js-count').forEach(el => el.textContent = response.order_items_count);
        document.querySelector('.js-cart-total .js-count-cart')?.setAttribute('data-count', response.order_items_count);

        const successButtons = document.querySelector('.js-content-add-to-cart-success'),
        addToCartForm = document.querySelector('.js-content-add-to-cart-form'),
        modalTitle = document.querySelector('#content-add-to-cart .modal-title');
        successButtons?.classList.remove('d-none');
        addToCartForm?.classList.add('d-none');
        modalTitle?.classList.add('d-none');

        // reset modal view
        document.getElementById('content-add-to-cart')?.addEventListener('hidden.bs.modal', ()=> {
            const successButtons = document.querySelector('.js-content-add-to-cart-success'),
            addToCartForm = document.querySelector('.js-content-add-to-cart-form');
            successButtons?.classList.add('d-none');
            addToCartForm?.classList.remove('d-none');
            modalTitle?.classList.remove('d-none');
        }, {once: true});
    },

    // change view to list or grid
    changeDisplayMode: (event) => {
        event.preventDefault();
        const type = $(event.currentTarget).attr('data-view') || '';
        if (type == 'gallery') {
            window.location.href = window.location.origin + window.location.pathname;
        } else {
            location.reload();
        }
        setCookie('view-mode', type, 365);
    },

    feedsSelection: function (event) {
        event.preventDefault();
        const category = $(event.target).data('category'),
        items =  $('.sec__main--feeds-section-feeds li');
        $('.sec__main--feeds-section-navigator li.active').removeClass('active');
        $(event.target).closest('li').addClass('active');

        $('.sec__main--feeds-section-feeds li.active').removeClass('active');

        if(!$('.filters-container').hasClass('open')) {
            $('.filters-container').addClass('open');
        }

        if (category === 'all') {
            items.addClass('active');
        } else {
            $.each(items, function (i, el) {
                if($(el).data('parent') === category) {
                    $(el).addClass('active');
                }
            });
        }
    },

    collapseFeeds: function () {
        const filtersContainer = $('.filters-container'),
        feedsContainer = $('#feedsCollapse');
        if(!filtersContainer.hasClass('open') && window.innerWidth >= 768 ) {
            filtersContainer.addClass('open');
        }
        if(feedsContainer.hasClass('in')) {
            feedsContainer.collapse('hide');
        }
    },

    searchType: (e) => {
        e.preventDefault();
        const inputName = $(e.target).data('name'),
        inputValue = $(e.target).data('value'),
        text = $(e.target).text();
        $('.' + inputName + '-selection').attr('name', inputName).val(inputValue);
        $('#search-' + inputName ).text(text);
    },

    sortSelection: (e) => {
        e.preventDefault();
        const sort = $(e.target).data('sort');
        $('#search_sort').val(sort);
        $('#searchForm').trigger('submit');
    },

    previewModal: (e) => {
        e.preventDefault();
        const target = $(e.currentTarget),
        title = target.attr('data-title') || '',
        content = target.attr('data-content') || '',
        type = target.attr('data-type') || '',
        source = target.attr('data-source') || '',
        redirect = target.attr('data-next') || '/',
        previewContainer = $('#preview-content-modal .js-content-preview-text');

        // clean modal
        previewContainer.html('');
        if($("#media-container").hasClass('jwplayer')) {
            window.jwplayer("media-container").remove();
        }

        // set redirect path
        $('.js-login-modal-trigger').attr('data-next', redirect);

        $('#preview-content-modal .js-content-preview-title').text(title);
        if(type === 'story') {
            previewContainer.text(content);
        } else if (type === 'photo' || type === 'graphic') {
            const imgSrc = target.closest('.item').find('.js-thumbnail').attr('src');
            previewContainer.html(`<img src="${imgSrc}" class="img-fluid marg-bot-16"><div></div>`);
            previewContainer.find('div').text(content);
        } else if (type === 'audio') {
            window.jwplayer("media-container").setup({
                width: "100%",
                height: "40",
                file: source,
                type: "mp3"
            });
        } else if (type === 'video') {
            window.jwplayer("media-container").setup({
                file: source,
                type: "mp4",
                width: "100%",
                aspectratio: "16:9"
            });
        }
        const modalInit = new Modal(document.getElementById('preview-content-modal') as HTMLElement);
        modalInit && modalInit.show();
    },

    feedsNavigationDetect: () => {
        if ($('.sec__main--feeds-section-navigator').length) {
            SearchForm.arrowDisplay();
        }
        SearchForm.countFilters();
    },

    feedsResize: () => {
        let debounce;
        $(window).resize(function() {
            if(debounce) clearTimeout(debounce);
            debounce = setTimeout(function(){SearchForm.arrowDisplay()}, 1000);
        });

    },

    arrowDisplay: () => {
        const scrollWidth = document.querySelector('.sec__main--feeds-section-navigator')!.scrollWidth,
        elWidth = (document.querySelector('.sec__main--feeds-section-navigator') as HTMLElement).offsetWidth;
        if((window.innerWidth < 1024) && (elWidth < scrollWidth)) {
            $('.sec__main--feeds-section-container').addClass('with-arrow');
        } else {
            $('.sec__main--feeds-section-container').removeClass('with-arrow');
        }
        SearchForm.feedsResize();
    },

    feedsSectionBgShow: () => {
        document.querySelector('.sec__main--feeds-section')?.classList.add('js-background');
    },

    feedsSectionBgHide: () => {
        document.querySelector('.sec__main--feeds-section')?.classList.remove('js-background');
    },

    clearField: () => {
        (document.querySelector('.search-bar .search-bar-input') as HTMLInputElement).value = '';
    },

    countFilters: () => {
        let count = document.querySelectorAll('.sec__main--search-form-filters .filters-container input:not(.default):checked').length;
        // count time range filter
        const timeOptions = document.querySelector('.sec__main--search-form-filters .filters-item.js-time .selected-options-cta');
        if(timeOptions?.classList.contains('d-none')) {
            count++;
        }
        document.querySelector('.sec__main--search-form-filters-more')?.setAttribute('data-count', `${count}`);
    },

    tdNotificationModal: () => {
        const tdNotif = document.getElementById('search-notification-modal') as HTMLElement;
        if(tdNotif) {
            const tdNotifModal = new Modal(tdNotif);
            tdNotifModal.show();
        }
    },

    populateServiceShareLink: (e) => {
        const url = e.currentTarget.getAttribute('data-link');
        (document.getElementById('js-service-share-link') as HTMLInputElement).value = url;
    },

    copyShareLink: (e) => {
        e.preventDefault();
        copy2clipboard('js-service-share-link');
    }
};

export default SearchForm;
