import $ from 'jquery';
import globalMessages from '../globalMessages';
import {nytNotification, checkForm} from '../globalFunctions';

// $ is the export of the jquery module.
var PasswordResetForm = {
    init: function () {
        $('#id-password-reset-form').on('submit', PasswordResetForm.post);
        $('#registration--tos').on('change', PasswordResetForm.removeCheckboxError);
    },

    post: function (event) {
        event.preventDefault();
        const code = window.location.search.split('=');
        if (checkForm(event.target)) {
            PasswordResetForm.register(code[1]);
        }
    },

    // remove error when change checkbox status
    removeCheckboxError: (event) => {
        const element = $(event.target);
        element.closest('label').removeClass('has-error');
    },

    register: function (code) {
        const dataForm = $('#id-password-reset-form').serializeForm();

        // disable save button to prevent multiple clicks
        $('.sec__main--password-reset-page-form-content-submit-cta').attr('disabled', 'disabled').addClass('loading');

        $.ajax({
            url: '/reset-password/?code=' + code,
            type: 'POST',
            data: dataForm,
            success: function (response) {
                PasswordResetForm.successFn(response);
            },
            error: function () {
                $('.sec__main--password-reset-page-form-content-submit-cta').removeAttr('disabled').removeClass('loading');
                nytNotification('error', globalMessages().genericRequestError);
            }
        });
    },

    successFn: function (response) {
        $('.sec__main--password-reset-page-form-content-submit-cta').removeAttr('disabled').removeClass('loading');

        $('.error.password-reset')
            .empty()
            .closest('.form-group').removeClass('has-error');
        if (response.success === 'true') {
            // update the page title, and the main message, display the login button
            window.top!.document.title = globalMessages().titleAfterPasswordWasChanged;
            $('.sec__main--password-reset-page-form-content-title')
            .text(globalMessages().titleAfterPasswordWasChanged);
            $('.sec__main--password-reset-page-form-content-description')
            .text(globalMessages().successResetPasswordMessage);

            $('#id-password-reset-form').remove();
            $('.password-reset-form-show-login').removeClass('d-none');
        } else {
            if (response.expired) {
                // the code has expired since the get request
                window.location.reload();
            } else {
                $.each(response.errors, function (id: string, error: string) {
                    if (id === '__all__') {  // case for invalid login - generic message.
                        nytNotification('error', error);
                    }
                    $('.error.password-reset.' + id)
                        .html(error)
                        .parent().addClass('has-error');
                });
            }
        }
    }
};

export default PasswordResetForm;
