import $ from 'jquery';
import { Modal } from 'bootstrap';
import globalMessages from '../globalMessages';
import {nytNotification, checkForm} from '../globalFunctions';

const LoginForm = {
    init: () => {
        $('.js-login-modal-trigger').on('click', LoginForm.openModal);
        $('.login-form').on('submit', LoginForm.loginPost);
    },

    openModal: (e) => {
        const redirect = $(e.target).attr('data-next');
        if (redirect) {
            $('#id_modal_login .js-redirect').val(redirect);
        } else {
            $('#id_modal_login .js-redirect').val('');
        }
    },

    loginPost: (event) => {
        event.preventDefault();
        const target = event.target;
        // do action only if form is valid
        if (checkForm(target)) {
            LoginForm.checkLogin(target);
        }
    },

    checkLogin: (form) => {
        $('.js-verify-session').attr('value', '1');
        const dataForm = $(form).serializeForm();

        $.ajax({
            url: '/login/',
            type: 'POST',
            data: dataForm,
            success: function (response) {
                LoginForm.checkLoginPopup(form, response);
            },
            error: function () {
                nytNotification('error', globalMessages().genericRequestError);
            }

        });
    },

    checkLoginPopup: (form, response) => {
        const loginModalInit = Modal.getInstance(document.getElementById('id_modal_login') as HTMLElement),
            multipleLoginModalInit = new Modal(document.getElementById('id_modal_multiple_login') as HTMLElement);
        if (response.show_popup) {
            loginModalInit && loginModalInit.hide();
            multipleLoginModalInit && multipleLoginModalInit.show();
            $('.js-multiple-login').on('click', {'form': form}, LoginForm.login);
        } else {
            LoginForm.login({'data': {'form': form}});
        }
    },

    login: (form) => {
        const multipleLoginModalInit = Modal.getInstance(document.getElementById('id_modal_multiple_login') as HTMLElement);
        multipleLoginModalInit && multipleLoginModalInit.hide();
        $('.js-verify-session').attr('value', '0');
        const dataForm = $(form.data.form).serializeForm();
        $.ajax({
            url: '/login/',
            type: 'POST',
            data: dataForm,
            success: function (response) {
                LoginForm.loginSuccess(response, form);
            },
            error: function () {
                nytNotification('error', globalMessages().genericRequestError);
            }
        });
    },
    
    loginSuccess: (response, form) => {
        $(form.data.form).find('.error').empty().closest('.form-group').removeClass('has-error');
        
        if (String(response.success) === 'true') {
            if(form.is_billing) {
                syn4('form_submit', {conversion: 'No', page_url: 'Billing Login', name: 'Billing Log In Form'});
            } else {
                syn4('form_submit', {conversion: 'No', page_url: window.location.href, name: 'Log In Form'});
            }
            if (response.redirect_url) {  // case staff user: redirect to admin interface
                window.location.href = window.location.protocol + '//' + response.redirect_url;
            } else {
                window.location.reload();
                $(document).scrollTop(0);
            }
        } else {
            $.each(response.errors, function (id: string, error: string) {
                if (id === '__all__') {  // case for invalid login - generic message.
                    nytNotification('error', error);
                }
                $(form.data.form).find('.error.' + id)
                    .html(error)
                    .closest('.form-group').addClass('has-error');
            });
        }
    }
};

export default LoginForm;
