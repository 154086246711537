import $ from 'jquery';
import CollectionModal from './CollectionModal';

// $ is the export of the jquery module.
var CollectionPopover = {
    init: function () {
        $('#content-gallery-modal .sec-main-collection-modal-form').off().on('submit', CollectionModal.addToCollection);
        $('#content-gallery-modal .create-collection').off().on('click', CollectionModal.newCollection);
    }
};

export default CollectionPopover;