import $ from 'jquery';
// $ is the export of the jquery module.
var CsrfTokenSetup = {
  init: function () {
    var csrftoken = this.getCookie('csrftoken')!;
    var self = this;

    $.ajaxSetup({
      beforeSend: function (xhr, settings) {
        if (!self.csrfSafeMethod(settings.type)) {
          xhr.setRequestHeader('X-CSRFToken', csrftoken);
        }
      }
    });
  },

  getCookie: function (name) {
    var cookieValue: string = '';
    if (document.cookie && document.cookie !== '') {
      var cookies = document.cookie.split(';');
      for (var i = 0; i < cookies.length; i++) {
        var cookie = $.trim(cookies[i]);
        // Does this cookie string begin with the name we want?
        if (cookie.substring(0, name.length + 1) === (name + '=')) {
          cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
          break;
        }
      }
    }
    return cookieValue;
  },
  csrfSafeMethod: function (method) {
    // these HTTP methods do not require CSRF protection
    return (/^(GET|HEAD|OPTIONS|TRACE)$/.test(method));
  },
};

export default CsrfTokenSetup

