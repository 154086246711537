import $ from 'jquery';

// $ is the export of the jquery module.
const MarketerFeedContent = {
    init: () => {
        $('.recent-content.articles article .article-title').addClass('full-width');
        $('[id^="js-modal-item-details-"]').on('click', MarketerFeedContent.initMarketerDetailsModal);
    },

    initMarketerDetailsModal: (event) => {
        const element = event.currentTarget;
        const dataType = $(element).attr('data-type');
        const dataID = $(element).attr('data-id');
        const feedTitle = $(element).attr('data-title') + '';
        const dataSource = $(element).attr('data-source');
        const dataContent = $(element).attr('data-content') + '';
        $('#js-marketer-data-image').addClass('hidden-photo-graphic-or-cartoon');
        $('#js-marketer-data-video').addClass('hidden-video');
        $('#js-marketer-data-audio').addClass('hidden-audio');
        $('#js-marketer-data-title').removeClass('photo-graphic-or-cartoon');
        $('#js-marketer-data-content').css('display', 'none');

        if (dataType === 'photo' || dataType === 'graphic') {
            $('#js-marketer-data-title').addClass('photo-graphic-or-cartoon');
            $('#js-marketer-data-image').addClass('visible-photo-graphic-or-cartoon').removeClass('hidden-photo-graphic-or-cartoon');
            if (dataSource) {
                $('#js-marketer-data-image').attr('src', `${dataSource}`);
            }
        } else if (dataType === 'story') {
            $('#js-marketer-data-content').css('display', 'block').text(dataContent);
        } else if (dataType === 'video') {
            $('#js-marketer-data-video').addClass('visible-video').removeClass('hidden-video');
            $('#js-video-link').empty();
            $(`<script> jwplayer('js-video-player').setup({file:'${dataSource}', type: 'mp4',width: '100%', apectratio: '16:9'})</script>`).appendTo('#js-video-link');
        } else if (dataType === 'audio') {
            $('#js-marketer-data-audio').addClass('visible-audio').removeClass('hidden-audio');
            $('#js-audio-link').empty();
            $(`<script> jwplayer('js-audio-player').setup({file:'${dataSource}', type: 'mp3', height: 40, width: '100%'})</script>`).appendTo('#js-audio-link');
        }
        $('#js-marketer-data-title').text(feedTitle);
        // check if contact rep btn exist and add attribute
        const btnDataTitle = $('.js-marketer-data-title');
        if(btnDataTitle.length) {
            btnDataTitle.attr('data-title',feedTitle);
            btnDataTitle.attr('data-link','/' + dataType + '/' + dataID);
        }
    },
};

export default MarketerFeedContent;
