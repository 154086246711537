import $ from 'jquery';
import globalMessages from '../globalMessages';
import {nytNotification} from '../globalFunctions';

var ResendConfirmationForm = {
    init: function () {
        $('#id-resend-activation-code').on('submit', ResendConfirmationForm.post);
    },

    post: (event) => {
        event.preventDefault();
        const form = event.target,
        code = $(form).find('input').val();
        $.ajax({
            url: '/resend-confirmation/', // the endpoint
            type: 'POST', // http method
            data: {
                code: code,
            },
            // handle a successful response
            success: function (response) {
                ResendConfirmationForm.postSuccess(response);
            },
            // handle a non-successful response
            error: function () {
                nytNotification('error', globalMessages().genericRequestError);
            }
        });
    },

    postSuccess: (response) => {
        if (response.success === 'true') {
            nytNotification('success', globalMessages().activationResend);
        } else {
            $.each(response.errors, function (id, error) {
                nytNotification('error', error);
            });
        }
    }
};

export default ResendConfirmationForm;
