import $ from 'jquery';
import globalMessages from '../globalMessages';
import {nytNotification, checkForm} from '../globalFunctions';
import { TomInput } from 'tom-select/dist/types/types';
import { selectCache } from '../tomSelect';

// $ is the export of the jquery module.
var CollectionModal = {
    init: function () {
        $('#addCollectionModal .sec-main-collection-modal-form').off().on('submit', CollectionModal.addToCollection);
        $('#addCollectionModal .create-collection').off().on('click', CollectionModal.newCollection);
        $('.js-collection-add').off().on('click', CollectionModal.collectionModalOpen);
    },

    collectionModalOpen: (e) => {
        const element = $(e.currentTarget),
        collectionForm = document.querySelector('.sec-main-collection-modal-form');
        (<HTMLInputElement>document.getElementById('js-item-id')).value = <string>element.attr('data-id');
        (<HTMLInputElement>document.getElementById('js-item-id-new')).value = <string>element.attr('data-id');
        (<HTMLInputElement>document.getElementById('js-item-type')).value = <string>element.attr('data-type');
        (<HTMLInputElement>document.getElementById('js-item-type-new')).value = <string>element.attr('data-type');
        // reset modal to initial state
        $('.sec-main-collection-modal .modal-content').removeClass('success');
        $('.sec-main-collection-modal .sec-main-collection-modal-collection-name').empty();
        $('.sec-main-collection-modal-form').removeClass('d-none');
        $('.sec-main-collection-modal-form.new').addClass('d-none');
        const collectionName = document.querySelector('.sec-main-collection-modal-form .js-collection-name') as HTMLInputElement;
        collectionName.value = '';
        selectCache.get(collectionForm).value.clear();
    },

    newCollection: (e) => {
        $(e.currentTarget).closest('.js-collection-container').find('.sec-main-collection-modal-form').toggleClass('d-none');
    },

    addToCollection: (e) => {
        e.preventDefault();
        const form = e.target;
        // add loading animation and add disable attribute
        $(form).find('.btn-primary').addClass('loading').attr('disabled', 'disabled');
        if (checkForm(form)) {
            CollectionModal.addToCollectionPost(form);
        } else {
            // remove loading animation and disable attribute
            $(form).find('.btn-primary').removeClass('loading').removeAttr('disabled');
        }
    },

    addToCollectionPost: (form) => {
        const dataForm = $(form).serializeForm(),
        collectionID = $(form).find('.js-collection-modal-dropdown').length ? $(form).find('.js-collection-modal-dropdown option:selected').attr('data-id') : '0';
        dataForm.action = "add";
        dataForm.collection_ids = [collectionID];
        $.ajax({
            url: '/collections/',
            type: 'POST',
            data: JSON.stringify(dataForm),
            success: function (response) {
                CollectionModal.addToCollectionPostSuccess(response, form);
            },
            error: function () {
                $(form).find('.btn-primary').removeClass('loading').removeAttr('disabled');
                nytNotification('error', globalMessages().genericRequestError);
            }
        });
    },

    addToCollectionPostSuccess: (response, form) => {
        // remove loading animation and disable attribute
        $(form).find('.btn-primary').removeClass('loading').removeAttr('disabled');
        if (response.success) {
            // event tracking
            syn4('search', {'page_url': window.location.href, 'search_action': response.data.item_title || response.data.item_id, 'search_type': 'Added to Collection'});

            $(form).closest('.sec-main-collection-modal-content').addClass('success');
            $(form).closest('.sec-main-collection-modal-content').find('.sec-main-collection-modal-collection-name').text(response.data.value);
            if (response.data.collection_ids[0] === '0') {
                $(form).closest('.sec-main-collection-modal-content').find('.js-view-collection').attr('href', '/collection/' + response.collections[0].id);
            } else {
                $(form).closest('.sec-main-collection-modal-content').find('.js-view-collection').attr('href', '/collection/' + response.data.collection_ids[0]);
            }
            
            // case for popover
            const popoverCheck = $(form).closest('.sec-main-collection-modal-content').hasClass('js-popover-check'),
            popoverSelect = $('#content-gallery-modal .popover-btn-content .select select');
            if(popoverCheck) {
                // reposition popover
                (<HTMLElement>document.getElementsByClassName('popover')[0]).style.top = parseInt((<HTMLElement>document.getElementsByClassName('popover')[0]).style.top, 10) + 88 + 'px'
                
                popoverSelect.html('');
                $.each (popoverSelect, (i, select) => {
                    $.each (response.collections, (y, item) => {
                        $(select).append(`<option value="${item.name}" data-id="${item.id}">${item.name} (${item.count})</option>`);
                    });
                    $(select).trigger("change");
                });
            }

            // populate add to collection modal dropdown
            const modalDropdown = document.querySelector('.sec-main-collection-modal-form .js-select select') as TomInput;
            modalDropdown.innerHTML = '';
            response.collections.forEach(item => {
                modalDropdown.insertAdjacentHTML('beforeend', `<option value="${item.name}" data-id="${item.id}">${item.name} (${item.count})</option>`)
            });
            // update tomselect
            modalDropdown?.tomselect?.clear();
            modalDropdown?.tomselect?.clearOptions();
            modalDropdown?.tomselect?.sync();

            // populate collections dropdown from topnavbar
            if (response.data.action === 'add') {
                const topNavCollectionsContainer = $('#js-collections-container');
                topNavCollectionsContainer.empty();
                $('.js-count-collections').text(response.collections.length);
                $.each(response.collections, (i, item) => {
                    topNavCollectionsContainer.append(
                        `<div id="js-collection-navlink-${item.id}" class="js-collection-item">
                        <a class="dropdown-item" target="_self" href="/collection/${item.id}">${item.name}</a>
                        </div>`
                    );
                });
                topNavCollectionsContainer.append('<a href="/collections/" class="dropdown-item manage" target="_self">Manage Collections</a>');
            }
        } else {
            $(form).find('.btn-primary').removeClass('loading').removeAttr('disabled');
            nytNotification('error', response.error);
        }
    },
};

export default CollectionModal;
