import BaseView from './BaseView';
import CsrfTokenSetup from '../services/CsrfTokenSetup';
import ActivityForm from '../services/ActivityForm';

function ActivityView (this: any, options) {  
    this.$el = BaseView.initView(options);

    this.init = function () {
        CsrfTokenSetup.init();
        ActivityForm.init();
    };

    this.init();
}
export default ActivityView;
