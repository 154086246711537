var NotificationsSocket = {
    init: () => {        
        // create websocket
        (window as any).chatSocket = new WebSocket(
            `wss://${window.location.host}/ws/notification/`
        );

        // what to do when receive messages
        (window as any).chatSocket.onmessage = function(e) {
            const data = JSON.parse(e.data);
            const profile = document.getElementById('manage-notifications');
            // populate profile notifications list
            if (profile) {
                NotificationsSocket.markAllRead();
                const notificationsContainer = profile?.querySelector('.sec__main--profile-content-notifications') as HTMLElement,
                notification = NotificationsSocket.createNotificationItem(data),
                loadBtn = document.getElementsByClassName('js-more-notifications')[0];                
                if(data.notifications) {
                    for (let index = 0; index < data.notifications.length; index++) {
                        notificationsContainer.append(NotificationsSocket.createNotificationItem(data.notifications[index]));
                    }
                } else {
                    notificationsContainer.prepend(notification);
                    notification.classList.add('unread');
                    // remove last notification to keep only 10 notifications per page, avoid when all notifications are displayed
                    if (notificationsContainer.children.length >= 10 && !loadBtn.classList.contains('d-none')) {
                        notificationsContainer.lastElementChild!.remove();
                    }
                }
                
                // evaluate if we need show load more btn
                const quotient = Math.floor(data.notifications_count/10),
                remainder = data.notifications_count % 10;
                const currentPage = parseInt(loadBtn.getAttribute('data-page') || '2');
                // less than 10 notifications or notifications count is multiple of 10                
                if(quotient === 0 || (quotient != 0 && remainder === 0) || (currentPage > quotient)) {
                    loadBtn.classList.add('d-none');
                } else {
                    loadBtn.classList.remove('d-none');
                }
                // update 'load more' button with next page
                if(data.notifications) {
                    loadBtn.setAttribute('data-page', `${currentPage + 1}`);
                }
            }

            if(!data.notifications) {
                const notificationDropdownItem = document.createElement('a'),
                notificationDropdown = document.getElementById('notifications-container') as HTMLElement;
                notificationDropdownItem.setAttribute('href', data.notification_link);
                notificationDropdownItem.classList.add('dropdown-item', 'unread');
                notificationDropdownItem.setAttribute('target', '_self');
                notificationDropdownItem.innerHTML = data.message;
                notificationDropdown.prepend(notificationDropdownItem);
                if(notificationDropdown.children.length >= 6) {
                    notificationDropdown.querySelector('.dropdown-item:nth-last-child(2)')!.remove();
                }
                // update top navigation count
                NotificationsSocket.updateNotificationsCount(data.unread_notifications);
            }
        };
        (window as any).chatSocket.onclose = function() {
            console.error('Chat socket closed unexpectedly');
        };

        // notification dropdown
        document.getElementById('notifications-dropdown')?.addEventListener('hide.bs.dropdown', NotificationsSocket.notificationsMarkRead);
        document.getElementById('notifications-container')?.querySelector('.manage')?.addEventListener('click', NotificationsSocket.preventDropdownClose);
    },

    notificationsMarkRead: () => {
        NotificationsSocket.updateNotificationsCount(0);
        NotificationsSocket.markAllRead();
    },

    preventDropdownClose: (e) => {
        // prevent dropdown to close when click on manage notifications link
        e.stopPropagation();
        e.preventDefault();
        window.location.href = e.currentTarget.getAttribute('href');
    },

    updateNotificationsCount: (count) => {
        (document.querySelector('.js-count-notifications')! as HTMLElement).innerText = count;
        document.querySelector('.notifications-count')?.setAttribute('data-count', count);
    },

    createNotificationItem: (data) => {
        const notification = document.createElement('a');
        notification.setAttribute('href', data.notification_link);
        notification.innerHTML =
            `<div class="notification-date">${data.notification_date}</div>
            <div class="notification-details">${data.message}</div>`;
        notification.classList.add('new', 'notification');

        return notification;
    },

    markAllRead: () => {
        // mark all notifications as read when arrive on Norifications page from profile
        NotificationsSocket.sendMessage({'type': 'read_notifications'});

        document.getElementById('notifications-container')?.querySelectorAll('.unread').forEach(msg => msg.classList.remove('unread'));
        const profile = document.getElementById('manage-notifications');
        // populate profile notifications
        if (profile) {
            profile?.querySelectorAll('.unread').forEach(notif => notif.classList.remove('unread'));
        }
    },

    sendMessage: (msg) => {
        const ws = (window as any).chatSocket;
        // Wait until the state of the socket is not ready and send the message when it is...
        NotificationsSocket.waitForSocketConnection(ws, function(){
            // console.log("message sent!!!");
            ws.send(JSON.stringify(msg));
        });
    },
    
    // Make the function wait until the connection is made...
    waitForSocketConnection: (socket, callback) => {
        setTimeout(function () {
            if (socket.readyState === 1) {
                // console.log("Connection is made")
                if (callback != null){
                    callback();
                }
            } else {
                // console.log("wait for connection...")
                NotificationsSocket.waitForSocketConnection(socket, callback);
            }

        }, 10); // wait 10 milisecond for the connection...
    }
};

export default NotificationsSocket;