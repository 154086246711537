import $ from 'jquery';
import { Modal } from 'bootstrap';
import globalMessages from '../globalMessages';
import {checkForm, fetchCall, formSerialize, nytNotification} from '../globalFunctions';

var ActivityCounter = {
    init: () => {
        document.querySelector('#activity-article-btn')?.addEventListener('click', ActivityCounter.getActivity);
        document.querySelector('.correction-update-form')?.addEventListener('submit', ActivityCounter.updateCorrection)
    },
    getActivity: (e) => {
        const btn = e.currentTarget,
        id = btn.dataset.id,
        url = `/activity-counter/${id}/`;
        btn.classList.add('loading');

        fetchCall(url, 'get', {},
            function (response) {
                btn.classList.remove('loading');
                if(response.success) {
                    ActivityCounter.getActivitySuccess(response.data);
                }
            }
        );
    },

    getActivitySuccess: (data) => {
        Object.keys(data).forEach((activity: string) => {
            (document.querySelector(`#activity-modal .activity-${activity}`) as HTMLElement).innerText = data[activity];
        });
        const modalInit = new Modal(document.getElementById('activity-modal') as HTMLElement);
        modalInit?.show();
    },

    updateCorrection: (e) => {
        e.preventDefault();
        if(checkForm(e.currentTarget)) {
            fetchCall(window.location.href, 'post', formSerialize(e.currentTarget),
                function (response) {
                    if(response.success) {
                        const modal = Modal.getInstance(document.getElementById('correction-update-modal') as HTMLElement);
                        modal?.hide();
                        nytNotification('success', globalMessages().requestSubmited);
                    } else {
                        if(response.errors.__all__) {
                            nytNotification('error', response.errors.__all__);
                        } else {
                            nytNotification('error', globalMessages().genericRequestError);
                        }

                    }
                }
            );
        }
    }
};

export default ActivityCounter;
