import $ from 'jquery';
import { Modal } from 'bootstrap';
import globalMessages from '../globalMessages';
import {nytNotification, numbersOnly, checkForm} from '../globalFunctions';

var ReportUseForm = {
    init: () => {
        $('#report-use-form').on('submit', ReportUseForm.post);
        numbersOnly('navision_nr');
    },

    post: function (event) {
        event.preventDefault();
        const form = event.target;
        // do action only if form is valid
        if (checkForm(form)) {
            ReportUseForm.ajaxCall(form);
        }
    },

    ajaxCall: (form) => {
        $('.report-use-btn').addClass('loading').attr('disabled', 'disabled');
        var dataForm = $(form).serializeForm();
        $.ajax({
            url: '/report-content-usage/',
            type: 'POST',
            data: dataForm,
            success: (response) => {
                ReportUseForm.ajaxCallSuccess(response);
            },
            error: function () {
                nytNotification('error', globalMessages().genericRequestError);
                $('.report-use-btn').removeClass('loading').removeAttr('disabled');
            }
        });
    },

    ajaxCallSuccess: (response) => {
        $('.report-use-btn').removeClass('loading').removeAttr('disabled');
        if (String(response.success) === 'true') {
            nytNotification('success', globalMessages().reportUse);
            const modalInit = Modal.getInstance(document.getElementById('report-use-modal') as HTMLElement);
            modalInit && modalInit.hide();
            (document.getElementById('report-use-form') as HTMLFormElement)!.reset();
        } else {
            $.each(response.errors, (id: string, error: string) => {
                $('#report-use-form').find('.error.' + id)
                    .html(error)
                    .parent().addClass('has-error');
            });
        }
    }
};

export default ReportUseForm;
