import $ from 'jquery';
import globalMessages from '../globalMessages';
import {nytNotification, checkForm} from '../globalFunctions';

const Blog = {
    init: () => {
        $('#blog-cta-subscribe').on('submit', Blog.validate);
    },

    validate: (e) => {
        e.preventDefault();
        if (checkForm(e.target)) {
            Blog.ctaPost(e.target);
        }
    },

    ctaPost: (form) => {
        $.ajax({
            url: '/pardot/editorial-subscribe/',
            type: 'POST',
            data: $(form).serializeForm(),
            success: function (response) {
                Blog.ctaPostSuccess(response,form);
            },
            error: function () {
                nytNotification('error', globalMessages().genericRequestError);
            }
        });
    },

    ctaPostSuccess: (response, form) => {
        if(response.success) {
            nytNotification('success', globalMessages().subscribeSuccess);
            $(form).find('input, select').val('').trigger('change');
        } else {
            $.each(response.errors, function (id: string, error: string) {
                $(form).find('.error.' + id)
                    .html(error)
                    .parent().addClass('has-error');
            });
        }
    }
};

export default Blog;
