import BaseView from './BaseView';
import ManageNotifications from '../services/ManageNotifications';

function NotificationsView (this: any, options) {
    this.$el = BaseView.initView(options);

    this.init = function () {
        ManageNotifications.init();
    };

    this.init();
}
export default NotificationsView;
