import $ from 'jquery';
import 'slick-carousel';

// Slick Carousel Docs: http://kenwheeler.github.io/slick
// used in /includes/slideshow.html and feeds.html
// this feature is part of /page/dynamic.html , /page/home.html , /page/services.html
var SlickCarousel = {
    init: function (selector, item?) {
        if (selector === ".slider-init") {
            $(item).not('.slick-initialized').slick({
                infinite: true,
                accessibility: false,
                autoplay: false,
                dots: false,
                slidesToShow: 4,
                slidesToScroll: 4,
                centerPadding: '0px',
                prevArrow: $(item).siblings(".gallery-arrow.left"),
                nextArrow: $(item).siblings(".gallery-arrow.right"),
                responsive: [
                    {
                        breakpoint: 1200,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 3
                        }
                    },
                    {
                        breakpoint: 992,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2
                        }
                    },
                    {
                        breakpoint: 550,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1
                        }
                    }
                ]
            });
        } else if (selector === '.recent-content' || selector === '.service-gallery') {
            $(selector).not('.slick-initialized').slick({
                infinite: true,
                accessibility: false,
                centerPadding: '0px',
                dots: false,
                rows: 0,
                prevArrow: $(selector).siblings(".gallery-arrow.left"),
                nextArrow: $(selector).siblings(".gallery-arrow.right"),
                responsive: [
                    {
                        breakpoint: 1200,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 3
                        }
                    },
                    {
                        breakpoint: 992,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2
                        }
                    },
                    {
                        breakpoint: 550,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1
                        }
                    }
                ]
            });
        } else if (selector === '.slider') {
            $(selector).not('.slick-initialized').slick({
                infinite: true,
                accessibility: false,
                centerPadding: '0px',
                centerMode: false,
                dots: true,
                prevArrow: $(selector).siblings(".gallery-arrow.left"),
                nextArrow: $(selector).siblings(".gallery-arrow.right"),
                responsive: [{
                    breakpoint: 768,
                    settings: "unslick",
                    adaptiveHeight: true
                }]
            });
        } else {
            $(selector).not('.slick-initialized').slick({
                infinite: true,
                accessibility: false,
                centerPadding: '0px',
                dots: false,
                prevArrow: $(selector).siblings(".gallery-arrow.left"),
                nextArrow: $(selector).siblings(".gallery-arrow.right"),
            });
        }
    },
    gallery: function () {
        $('.gallery-slider').not('.slick-initialized').slick({
            accessibility: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            fade: false,
            lazyLoad: 'ondemand',
            prevArrow: $('.gallery-slider').siblings(".gallery-arrow.left"),
            nextArrow: $('.gallery-slider').siblings(".gallery-arrow.right"),
        });

        // On before slide change match active thumbnail to current slide
        $('.gallery-slider').on('beforeChange', function (event, slick, currentSlide, nextSlide) {
            const mySlideNumber = nextSlide;
            $('.slider-nav-thumbnails .slick-slide').removeClass('slick-active');
            $('.slider-nav-thumbnails .slick-slide.slick-current').eq(mySlideNumber).addClass('slick-current');
        });
    }
};

export default SlickCarousel;
