import BaseView from './BaseView';
import TimesDigest from '../services/TimesDigest';

function TimesDigestView (this: any, options) {  
    this.$el = BaseView.initView(options);

    this.init = function () {
        TimesDigest.init();
    };

    this.init();
}
export default TimesDigestView;