import SearchForm from "./SearchForm";

const EnrichedFeed = {
    init: () => {
        document.querySelector('.sec__main--profile-content .search-bar .icon-clear')?.addEventListener('click', SearchForm.clearField);
        document.querySelectorAll('.sec__main--profile-content .filters-expand-content input[type="checkbox"]').forEach(el => el.addEventListener('change', SearchForm.checkboxSelect));
        document.querySelectorAll('.sec__main--profile-content .filters-expand-content input[type="radio"]').forEach(el => el.addEventListener('change', SearchForm.radioSelection));
        document.querySelectorAll('.sec__main--profile-content .filters-item.filters-expand .filters-item-cta .filters-item-link.expand-link').forEach(el => el.addEventListener('click', SearchForm.activateItem));
        document.addEventListener('mouseup', SearchForm.closeFilters);
        
        document.querySelectorAll('.sec__main--profile-content .filters-item-cta .selected-options-cta').forEach(el => el.addEventListener('click', SearchForm.deleteSelections));
        $('#range-min, #range-max').on('input', SearchForm.restrictRange);
        document.querySelector('.js-filters-reset')?.addEventListener('click', EnrichedFeed.resetSearch);
    },

    // reset form to default values
    resetSearch: (e) => {
        e.preventDefault();
        const filters = document.querySelectorAll('.sec__main--search-form-filters .filters-container .filters-items input.default');
        filters.forEach(el => (el as HTMLInputElement).click());
    },

};
export default EnrichedFeed;