import $ from 'jquery';
// import SlickCarousel from './SlickCarousel';
// import ContentInquiryForm from './ContentInquiryForm';

// $ is the export of the jquery module.
function SliderCategory(this: any) {
    this.init = () => {
        this.initTemplates();
    };

    this.initTemplates = () => {
        this.feedsContainer = $('.js-feeds-container');
        this.categoryFeedTemplate = $('#category_template');
        this.categoryFeedItemTemplate = $('#category_item_template');
        this.addToCollection = $('#add_to_collection');
    };

    this.getLink = (item) => {
        let link: string;

        if (item.type && item._id) {
            link = item.type + '/' + item._id;
        } else {
            link = '';
        }
        return link;
    };

    this.imageWithArtSrc = (item) => {
        let imageWhithArtSrc;
        if (item.hasOwnProperty('thumbnail_url')) {
            imageWhithArtSrc = item.thumbnail_url;
        }
        return imageWhithArtSrc;
    };

    this.getArticleIcon = (item) => {
        let icon, type = item.type;
        if (!type) {
            return '';
        }

        if (type == 'story') {
            icon = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 20"><path d="M8,8V0H1A1,1,0,0,0,0,1V19a1,1,0,0,0,1,1H15a1,1,0,0,0,1-1V8Z"/><polygon points="10 6 16 6 10 0 10 6"/></svg>';
        } else if (type == 'photo') {
            icon = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 16"><circle cx="10" cy="9.33" r="2.5"/><path d="M19,2.67H17a.85.85,0,0,1-.83-.7L15.83.83C15.71.36,15.46,0,15,0H5c-.46,0-.72.38-.83.83L3.87,2a.85.85,0,0,1-.83.7H1a1,1,0,0,0-1,1V15a1,1,0,0,0,1,1H19a1,1,0,0,0,1-1V3.67A1,1,0,0,0,19,2.67ZM10,13.83a4.5,4.5,0,1,1,4.5-4.5A4.51,4.51,0,0,1,10,13.83ZM16.67,7a1,1,0,1,1,1-1A1,1,0,0,1,16.67,7Z"/></svg>';
        } else if (type == 'graphic') {
            icon = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><polygon points="20 20 0 20 0 0 2 0 2 18 20 18 20 20"/><rect x="4" y="4" width="4" height="12"/><rect x="10" width="4" height="16"/><rect x="16" y="8" width="4" height="8"/></svg>';
        } else if (type == 'cartoon') {
            icon = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 14.09"><path d="M17.7,2.31A1.79,1.79,0,0,0,15.56,3c-.39.49-1.33,1.85-2.36.84S12.82,2,11.53.68s-5.06-.8-8.81,2S-.28,9,1.28,10.89c1.82,2.2,4.65,3.49,9.23,3.15,4.9-.37,9.09-3.37,9.45-6.68A4.87,4.87,0,0,0,17.7,2.31ZM4.9,9.9A1.5,1.5,0,1,1,5,7.78,1.49,1.49,0,0,1,4.9,9.9Zm4,2.14A1.5,1.5,0,1,1,9,9.92,1.5,1.5,0,0,1,8.89,12Zm-.25-7.5C7.33,5,6.05,4.67,5.8,3.88s.6-1.77,1.91-2.2,2.59-.13,2.85.66S10,4.11,8.64,4.54Zm4.86,7.2a1.5,1.5,0,1,1,.06-2.12A1.5,1.5,0,0,1,13.5,11.74Zm3.72-2.65A1.5,1.5,0,1,1,17.28,7,1.5,1.5,0,0,1,17.22,9.09Z"/></svg>';
        } else if (type == 'audio') {
            icon = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 15.99"><path d="M1,5H5.73a0,0,0,0,1,0,0v6a0,0,0,0,1,0,0H1a1,1,0,0,1-1-1V6A1,1,0,0,1,1,5Z"/><polygon points="10.99 14.99 4.98 10.99 4.98 5 10.99 0.99 10.99 14.99"/><path d="M14,8a7,7,0,0,1-1.78,4.64l1.68,1.12a8.93,8.93,0,0,0,0-11.53L12.21,3.35A7,7,0,0,1,14,8Z"/><path d="M18,8a11,11,0,0,1-2.44,6.88L17.23,16a12.92,12.92,0,0,0,0-16L15.56,1.11A11,11,0,0,1,18,8Z"/></svg>';
        } else if (type == 'video') {
            icon = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 12"><path d="M1,0H12a0,0,0,0,1,0,0V12a0,0,0,0,1,0,0H1a1,1,0,0,1-1-1V1A1,1,0,0,1,1,0Z"/><polygon points="20 12 14 9.37 14 2.63 20 0 20 12"/></svg>';
        }
        return icon;
    };

    this.getTitle = (item) => {
        let title = '';

        if (item.type === 'photo') {
            // photo : slug-> headline-> caption
            if (item.slug) {
                title = item.slug;
            } else if (item.headline) {
                title = item.headline;
            } else if (item.caption) {
                title = item.caption;
            }
        } else if (item.type === 'video' || item.type === 'story') {
            // articles, video : title -> headline -> caption
            if (item.title) {
                title = item.title;
            } else if (item.headline) {
                title = item.headline;
            } else if (item.caption) {
                title = item.caption;
            }
        } else if (item.type === 'graphic') {
            // graphic : filename -> caption
            if (item.filename) {
                title = item.filename;
            } else if (item.caption) {
                title = item.caption;
            }
        } else if (item.type === 'audio') {
            // audio : title -> description -> filename
            if (item.title) {
                title = item.title;
            } else if (item.description) {
                title = item.description;
            } else if (item.filename) {
                title = item.filename;
            }
        }
        return title;

    };

    this.getTitleAlt = (item) => {
        let titleAlt;

        if ((item.type === 'photo' || item.type === 'graphic') && item.caption) {
            titleAlt = item.caption;
        } else {
            titleAlt = this.getTitle(item);
        }

        return titleAlt;

    };

    this.detectMedia = (item) => {
        let media = {
            hasPhotos: '',
            title: ''
        };
        if (item.type === 'photo') {
            media = {
                hasPhotos: 'media',
                title: 'hidden'
            };
        }

        return media;

    };

    this.imageSrc = (categoryItem, static_url) => {
        let imageSrc;
        const image = categoryItem.thumbnail_url;
        if (!image && !categoryItem.hasOwnProperty('thumbnail_url')) {
            imageSrc = '<img src="' + static_url + 'img/logo-grey.svg" class="content-thumbnail no-thumbnail" alt="" />';
        } else {
            imageSrc = '<img src="' + image + '" alt="" class="content-thumbnail" />';
        }

        return imageSrc;
    };

    this.photoOrNot = (categoryItem) => {
        let photoOrNot;
        const image = categoryItem.thumbnail_url;
        if (!image && !categoryItem.hasOwnProperty('thumbnail_url')) {
            //if image will be null, we add a class to be visible the content details
            photoOrNot = 'no-thumbnail';
        } else {
            photoOrNot = '';
        }
        return photoOrNot;
    };

    this.author = (categoryItem) => {
        let author;
        const prefixAuthor = 'By ';

        if (categoryItem.author) {
            author = prefixAuthor + '<a href="/search/?q=' + categoryItem.author + '&amp;format=person&amp;time=all&amp;language=english">' + categoryItem.author + '</a>';
        } else if (categoryItem.byline) {
            author = prefixAuthor + '<a href="/search/?q=' + categoryItem.byline + '&amp;format=person&amp;time=all&amp;language=english">' + categoryItem.byline + '</a>';
        } else if (categoryItem.producer) {
            author = prefixAuthor + '<a href="/search/?q=' + categoryItem.producer + '&amp;format=person&amp;time=all&amp;language=english">' + categoryItem.producer + '</a>';
        } else {
            author = '';
        }
        return author.toLowerCase();
    };

    this.version = (categoryItem) => {
        let version = '';

        if (categoryItem.version) {
            version = ` | <span class="version">${categoryItem.version} Update</span>`;
        }
        return version;
    };

    this.selectors = (categoryItem) => {
        let selectors;
        if (categoryItem.hasOwnProperty('services_names') && categoryItem.services_names.length > 0) {
            // display only first service name
            selectors = categoryItem.services_names[0];
        } else {
            selectors = '';
        }

        return selectors;
    };

    this.wordCount = (categoryItem) => {
        let wordCount;
        const suffixWordCount = ' Words';
        if (categoryItem.wordCount) {
            wordCount = categoryItem.wordCount + suffixWordCount;
        } else {
            wordCount = '';
        }
        return wordCount;
    };

    this.relatedMediaCount = (categoryItem) => {
        let returnText = '';
        if(categoryItem.type === 'story') {
            const mediaCount = categoryItem.relatedMedia.length;
            if (mediaCount && mediaCount > 1) {
                returnText = 'with ' + mediaCount + ' visuals';
            } else if(mediaCount && mediaCount === 1) {
                returnText = 'with ' + mediaCount + ' visual'
            }
        }
        return returnText;
    };

    this.duration = (categoryItem) => {
        let duration;
        const video = ' Video', audio = ' Audio';
        if (categoryItem.duration) {
            duration = categoryItem.duration + (categoryItem.type === 'video' ? video : audio);
        } else {
            duration = '';
        }
        return duration;
    };

    this.contactRepButton = (categoryItemsElem, feedItemTemplate, contactSalesRep) => {
        feedItemTemplate.find('.article-btn').append(contactSalesRep);
        feedItemTemplate.find('.article-btn .btn').attr({
            'data-title': this.getTitle(categoryItemsElem),
            'data-link': '/' + categoryItemsElem.type + '/' + categoryItemsElem._id
        });
    };

    this.renderCategory = (categoryName, categoryItems, categoryIsActive, viewAllLink, static_url, show_contact_rep, downloadOnly) => {
        let categoryTemplate = this.categoryFeedTemplate[0].innerHTML;
        const addToCollection = this.addToCollection[0].innerHTML;
        categoryTemplate = categoryTemplate.replace('{[{title}]}', categoryName);

        const categoryLinkElement = "<a class='category-link' href='" + viewAllLink + "' >View All</a>";
        categoryTemplate = categoryTemplate.replace('{[{view_all_link}]}', categoryLinkElement);

        categoryTemplate = $(categoryTemplate);

        for (const i in categoryItems) {
            if (categoryItems.hasOwnProperty(i)) {
                const feedItemTemplate = this.renderCategoryItem(categoryItems[i], static_url);

                if (!categoryItems[i].hasOwnProperty('thumbnail_url')) {
                    feedItemTemplate.find('.article-photo').addClass('initial-background-color');
                }
                categoryTemplate.find('.articles').append(feedItemTemplate);

                if (categoryItems[i].can_download && (categoryItems[i].type != 'audio' || categoryItems[i].type != 'video')) {
                    feedItemTemplate.find('p').append(addToCollection
                        .replace('{[{content_id}]}', categoryItems[i]._id)
                        .replace('{[{content_title}]}', this.getTitleAlt(categoryItems[i]))
                        .replace('{[{content_type}]}', categoryItems[i].type)
                    );
                }
            }
        }

        this.feedsContainer.append(categoryTemplate);
    };

    this.renderCategoryItem = (categoryItem, static_url) => {
        let itemTemplate = this.categoryFeedItemTemplate[0].innerHTML;

        const title = this.getTitle(categoryItem);
        const titleAlt = this.getTitleAlt(categoryItem);
        const media = this.detectMedia(categoryItem);
        const link = this.getLink(categoryItem);
        const icon = this.getArticleIcon(categoryItem);
        const imageSrc = this.imageSrc(categoryItem, static_url);
        const photoOrNot = this.photoOrNot(categoryItem);
        const author = this.author(categoryItem);
        const version = this.version(categoryItem);
        const selectors = this.selectors(categoryItem);
        const wordCount = this.wordCount(categoryItem);
        const relatedMediaCount = this.relatedMediaCount(categoryItem);
        const duration = this.duration(categoryItem);

        itemTemplate = itemTemplate
            .replace('{[{article_title}]}', title)
            .replace('{[{article_title_link}]}', titleAlt)
            .replace('{[{article_media}]}', media.hasPhotos)
            .replace('{[{article_media_title}]}', media.title)
            .replace('{[{article_link}]}', link)
            .replace('{[{article_image_src}]}', imageSrc)
            .replace('{[{article_photo_or_not}]}', photoOrNot)
            .replace('{[{article_icon}]}', icon)
            .replace('{[{article_author}]}', author)
            .replace('{[{article_date}]}', (categoryItem.importedAt ? categoryItem.importedAt : ''))
            .replace('{[{article_version}]}', version)
            .replace('{[{article_selectors}]}', selectors)
            .replace('{[{article_wordCount}]}', wordCount)
            .replace('{[{relatedMediaCount}]}', relatedMediaCount)
            .replace('{[{article_duration}]}', duration);

        return $(itemTemplate);
    };
}

export default SliderCategory;
